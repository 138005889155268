import React, { useEffect, useState } from 'react';
import { commonStyles, TableStyles } from '../../Styles/commonStyles';
import { Box, Typography, TableBody, TableCell, TableRow, Table, Avatar, TableContainer, Paper, Button } from '@mui/material';
import { SearchInputField, TablePagination, BasicMenu, DropDown, Heading, CustomTooltip } from '../../Utils/customField';
import { commonServices } from '../../Services/commonServices';
import { ApiConfig } from '../../Config/config';
import { Loader, NoData } from '../../Components/lotties';
import { dateFormat, nameFormatter } from '../../Utils/functions';
import BasicTable from '../../Utils/Table';
import { ProfileImage } from '../../Utils/model';
import { toast } from 'react-toastify';
import { CommonModel } from '../../Utils/model';
import { Icons } from '../../Styles/Icons';

const UserManagement = () => {
  //Image Url
  const baseUrl = ApiConfig.baseUrl;
  const [profileOpen, setProfileOpen] = React.useState(false);
  const TableHeadRow = ['S.No', 
    //'Registred Date', 
    'User Name', 'Mobile Number', 'Gender', 'DOB',
    // 'No of Songs Listed',
    'Status','Profile',  'Selfie', 'Action'];

  //Table Search, Pagination & Loader
  const [tablePagination, setTablePagination] = useState({
    totalPages: 0,
    currentPage: 1,
    search: '',
    loader: true,
    filter: 3,
    totalUser: 0,
  });

  //Get User List API
  const [getUserListData, setGetUserListData] = useState([]);
  const getUserList = async () => {
    setTablePagination((prev) => ({ ...prev, loader: true }));
    let payload = {
      "pageNumber": tablePagination?.currentPage,
      "pageSize": 10,
      "search": tablePagination?.search,
      "filter": tablePagination?.filter,
    }
    const res = await commonServices.getUserList(payload);
    if (res?.status === 200) {
      const totalPages = Math.ceil(res?.data?.data?.count / 10);

      setTimeout(() => {
        setTablePagination((prev) => ({ ...prev, totalPages: totalPages, totalUser: res?.data?.data?.totalUsers, loader: false }));
      }, [1000]);
      setGetUserListData(res?.data?.data?.rows);
    }
  }

  useEffect(() => {
    getUserList();
  }, [tablePagination?.currentPage]);

  //handleOption from Menu - 0 - Pending, 1 - Verified, 2 - Declined Option for Selfie Verifications
  const handleOption = async (value, userId) => {
    let payload = {
      "userId": userId,
      "status": value
    }
    const res = await commonServices.selfieVerification(payload);
    console.log(res);
    if (res?.status === 200) {
      toast.success(res?.data?.message, commonStyles.toastSuccess);
      getUserList();
    }
  }

  //Search Function
  const handleSearch = async (e) => {
    setTablePagination((prev) => ({ ...prev, search: e.target.value }));
    let payload = {
      "pageNumber": tablePagination?.currentPage,
      "pageSize": 10,
      "search": e.target.value,
      "filter": tablePagination?.filter,
    }
    const res = await commonServices.getUserList(payload);
    if (res?.status === 200) {
      setGetUserListData(res?.data?.data?.rows);
      const totalPages = Math.ceil(res?.data?.data?.count / 10);
      setTablePagination((prev) => ({ ...prev, totalPages: totalPages }));
    }
  }

  //Filter Functions
  const handleChange = async (event) => {
    setTablePagination((prev) => ({ ...prev, loader: true, filter: event.target.value }));
    const payload = {
      "pageNumber": tablePagination?.currentPage,
      "pageSize": 10,
      "search": tablePagination?.search,
      "filter": event.target.value,
    }
    const res = await commonServices.getUserList(payload);
    console.log(res);
    if (res?.status === 200) {
      const totalPages = Math.ceil(res?.data?.data?.count / 10);
      setTimeout(() => {
        setTablePagination((prev) => ({ ...prev, totalPages: totalPages, loader: false }));
      }, [1000]);
      setGetUserListData(res?.data?.data?.rows);
    }
  };

  const [userProfile, setUserProfile] = useState({});
  const handleProfileImg = (profileUrl) => {
    setProfileOpen(true);
    setUserProfile(profileUrl);
  }

  //Unblock User
  const [modelOpen, setModelOpen] = useState(false);
  const [blockUser, setBlockUser] = useState({});
  const [status, setStatus] = useState(1);
  const handleUnblock = (userId) => {
    setBlockUser(userId);
    setStatus(5);
    setModelOpen(true);
  }

  const handleUnblockApi = async () => {
    let payload = {
      "userId": blockUser
    }
    if (status === 5) {
      const res = await commonServices.unBlockUser(payload);
      if (res?.status === 200) {
        toast.success(res?.data?.message, commonStyles.toastSuccess);
        setModelOpen(false);
        getUserList();
      }
    } else if (status === 6) {
      let payload = {
        "userId": blockUser,
        "type": 1,
        "description": "User has been blocked",
      }
      const res = await commonServices.chatReportBlocks(payload);
      if (res?.status === 200) {
        toast.success(res?.data?.message, commonStyles.toastSuccess);
        setModelOpen(false);
        getUserList();
      }
    } else if (status === 7) {
      let payload = {
        "userId": blockUser,
        "type": 3,
        "description": "User has been deleted",
      }
      const res = await commonServices.chatReportBlocks(payload);
      if (res?.status === 200) {
        toast.success(res?.data?.message, commonStyles.toastSuccess);
        setModelOpen(false);
        getUserList();
      }
    }
  }

  const handleBlockFun = (data) => {
    setStatus(6);
    setModelOpen(true);
    setBlockUser(data.userId);
  }

  const handleDeleteFun = (row) => {
    setStatus(7);
    setModelOpen(true);
    setBlockUser(row.userId);
  }

  

  return (
    <Box sx={commonStyles.outlet_root}>
      <CommonModel open={modelOpen} setOpen={setModelOpen} status={status} handleRemoveApi={handleUnblockApi} />
      <Box sx={commonStyles.flex_center_between}>
        <Box>
        <Heading title="User Management" />
        <Typography sx={commonStyles.total_user}>
        Total Registered Users : {tablePagination?.totalUser} </Typography>
        </Box>

        <Box sx={commonStyles.flex_center_between}>
          <DropDown
            handleChange={handleChange} value={tablePagination?.filter}
          />
          <SearchInputField tablePagination={tablePagination} handleSearch={handleSearch} />
        </Box>
      </Box>
      {tablePagination?.loader && <Loader />}
      {/* Table */}
      {getUserListData?.length !== 0 && getUserListData ?
        <>
          <TableContainer component={Paper} sx={TableStyles.tableContainer}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <BasicTable TableHeadRow={TableHeadRow} />
              <TableBody sx={TableStyles.tableBody}>
                {getUserListData?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell sx={TableStyles.tableCell}>{index + 1}</TableCell>
                    {/* <TableCell sx={TableStyles.tableCell}>{dateFormat(row?.createdAt)}</TableCell> */}
                    <TableCell sx={TableStyles.tableCell}>
                      {nameFormatter(row?.name)}
                    </TableCell>
                    <TableCell sx={TableStyles.tableCell}>+{row?.countryPhoneCode} {row?.mobileNumber}</TableCell>
                    <TableCell sx={TableStyles.tableCell}>{row?.genderId === 1 ? "Male" : "Female"}</TableCell>
                    <TableCell sx={[TableStyles.tableCell, { textWrap: "NoWrap" }]}>{row?.dob}</TableCell>
                    {/* <TableCell sx={TableStyles.tableCell}>{row?.count}</TableCell> */}
                    <TableCell sx={TableStyles.tableCell}>
                      {row?.isBlocked ? "Blocked" : row?.isDeleted ? "Deleted" : "Active"}
                    </TableCell>
                    
                    <TableCell>
                      <ProfileImage open={profileOpen} setOpen={setProfileOpen} img={userProfile} setImg={setUserProfile} />
                      {row.profilePic === "" ?
                        <Avatar alt="Avatar" style={TableStyles.profileImg} /> :
                        <img src={baseUrl + row?.profilePic} alt="profile" style={TableStyles?.profileImg}
                          onClick={() => handleProfileImg(row.profilePic)} />
                      }
                    </TableCell>
                    
                    <TableCell sx={TableStyles.tableCell}>
                      {
                        row.profilePic === "" ? "Incompleted Profile" :
                          row.profilePicStatus === 1 ?
                            <Button sx={commonStyles.verifiedText}>Verified</Button>
                            :
                            <BasicMenu
                              status={row?.profilePicStatus}
                              handleOption={handleOption}
                              userId={row?.userId}
                            >
                            </BasicMenu>
                      }
                    </TableCell>
                    <TableCell sx={TableStyles.tableCell}>
                      <Box>
                        
                        {row?.isBlocked ?
                          <Button sx={commonStyles.unblockText} onClick={() => handleUnblock(row?.userId)}>Unblock</Button>
                          :
                          row?.isDeleted ?
                          <Typography sx={commonStyles.DeleteText}>Deleted</Typography>
                          :
                          <>
                           
                            <CustomTooltip title="Block the User">
                              <Icons.BlockIcon sx={commonStyles.blockIcon} onClick={() => { handleBlockFun(row) }} />
                            </CustomTooltip>
                            <CustomTooltip title="Delete the User">
                              <Icons.HighlightOffIcon sx={commonStyles.deleteIcon} onClick={() => { handleDeleteFun(row) }} />
                            </CustomTooltip> 
                          </>
                          }
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination tablePagination={tablePagination} setTablePagination={setTablePagination} />
        </>
        :
        // No Data Availble Lottie Animation
        <NoData />
      }
    </Box>
  )
}

export default UserManagement
