import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import SideNav from '../Components/sideNav';
import Header from '../Components/header';
import { loginStyles, rootLayoutStyles } from '../Styles/commonStyles';

const RootLayout = () => {
    return (
        <>
            <Box sx={loginStyles.mainDiv}>
                <Box sx={rootLayoutStyles.rootDiv}>
                    <Box sx={rootLayoutStyles.sidenavDiv}>
                        <SideNav />
                    </Box>
                    <Box sx={rootLayoutStyles.contentDiv}>
                        <Header />
                        <Outlet />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default RootLayout
