import React, { useEffect, useState } from 'react';
import BasicTable from '../../Utils/Table';
import { useLocation, useNavigate } from 'react-router-dom';
import { dateFormat } from '../../Utils/functions';
import { commonServices } from '../../Services/commonServices';
import { commonStyles, TableStyles } from '../../Styles/commonStyles';
import { Heading, ImageTitle, TablePagination } from '../../Utils/customField';
import { TableBody, TableCell, TableRow, Table, TableContainer, Box, Paper } from '@mui/material';
import { Icons } from '../../Styles/Icons';

const ReportList = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const { userId, type } = location.state;
  const TableHeadRow = ['S.No', 'Name', 'Date', 'Reason'];
  const [tablePagination, setTablePagination] = useState({
    totalPages: 0,
    currentPage: 1,
    search: '',
    loader: true,
  })

  //Get Chat Report List API
  const [reportedblockUserData, setReportedblockUserData] = useState([]);
  const reportedblockUserList = async () => {
    let payload = {
      "pageNumber": tablePagination?.currentPage,
      "pageSize": 10,
      "userId": userId,
      "type": type
    }
    const res = await commonServices.reportedblockUserList(payload);
    if (res?.status === 200) {
      setReportedblockUserData(res?.data?.data?.rows);
      const totalPages = Math.ceil(res?.data?.data?.count / 10);
      setTablePagination((prev) => ({ ...prev, totalPages: totalPages }));
    }
  }

  useEffect(() => {
    reportedblockUserList();
  }, [tablePagination.currentPage]);

  const navigateBack = () => {
    Navigate('/Admin/Report', { state: { tabStatusNew: 2 } });
  }

  return (
    <>
      <Box sx={commonStyles.outlet_root}>
        <Box sx={commonStyles.flex_center_start}>
          <Icons.KeyboardBackspaceIcon sx={commonStyles.backIcon} onClick={() => navigateBack()} />
          <Heading title={type === 1 ? "Blocked List" : "Report List"} />
        </Box>
        <TableContainer component={Paper} sx={TableStyles.tableContainer}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <BasicTable TableHeadRow={TableHeadRow} />
            <TableBody>
              {reportedblockUserData?.map((data, index) => (
                <TableRow key={index}>
                  <TableCell sx={TableStyles.tableCell}>{index + 1}</TableCell>
                  <TableCell sx={TableStyles.tableCell}>
                    <ImageTitle url={data?.profilePic} title={data?.name} />
                  </TableCell>
                  <TableCell sx={TableStyles.tableCell}>{dateFormat(data?.createdAt)}</TableCell>
                  <TableCell sx={TableStyles.tableCell}>
                    {type === 1 && "Blocked"}
                    {type === 2 && data?.reason}
                    {type === 3 && data?.description}
                    <br />
                    {type === 2 && data?.reason === "Other" && 'Description:' + data?.description}

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination tablePagination={tablePagination} setTablePagination={setTablePagination} />
      </Box>
    </>
  )
}

export default ReportList
