import { globalColor } from "./globalColor";

export const promoStyles = {
  promoDiv: {
    backgroundColor: "#261E45",
    color: globalColor.textPrimary,
    height: "100%",
  },

  d_flex: {
    display: "flex",
    alignItems: "center",
    textWrap: "noWrap",
  },

  d_flex_center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  d_flex_start: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  d_flex_between: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  d_flex_around: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },

  py2: {
    padding: "2rem 0",
  },
  px2: {
    padding: "0rem 2rem",
  },

  anchor: {
    textDecoration: "none",
    fontFamily: "inter",
    color: "#fff",
    padding: "0.6rem 1rem",
    fontWeight: "400",
    fontSize: "0.9rem",
    textWrap: "noWrap",
    "&:hover": {
      backgroundColor: "#7150D0",
      borderRadius: "25px",
      color: globalColor.textPrimary,
    },

    "@media (max-width: 600px)": {
      fontSize: "0.6rem",
      padding: "0.6rem 0.8rem",
    },
  },

  anchorActive: {
    textDecoration: "none",
    fontFamily: "inter",
    color: globalColor.textPrimary,
    padding: "0.6rem 1rem",
    fontWeight: "400",
    fontSize: "0.9rem",
    textWrap: "noWrap",
    backgroundColor: "#7150D0",
    borderRadius: "25px",
    "&:hover": {
      color: "#fff",
    },

    "@media (max-width: 600px)": {
      fontSize: "0.6rem",
      padding: "0.6rem 0.8rem",
    },
  },

  star: {
    marginRight: "1rem",
    width: "30px",
    height: "auto",
    "@media (max-width: 600px)": {
      marginRight: "0.2rem",
      width: "10px",
      height: "auto",
    },
  },

  h3: {
    fontFamily: "inter",
    fontSize: "3rem",
    textAlign: "center",
    fontWeight: "900",
    padding: "2rem 0",
    "@media (max-width: 600px)": {
      fontSize: "1.5rem",
      padding: "1rem 0",
    },
  },

  h31: {
    fontFamily: "inter",
    fontSize: "3rem",
    textAlign: "center",
    fontWeight: "800",
    lineHeight: "1.2",
    "@media (max-width: 600px)": {
      fontSize: "1rem",
      padding: "0.5rem 0",
    },
  },
  h32: {
    fontFamily: "inter",
    fontSize: "3rem",
    position: "relative",
    // textAlign: 'center',
    fontWeight: "800",
    lineHeight: "1.2",
    padding: "2rem 0",
    zIndex: 1,
    "@media (max-width: 600px)": {
      fontSize: "1rem",
      padding: "0.5rem 0",
      textAlign: "center",
    },
    "@media (min-width: 600px) and ( max-width: 1024px)": {
      fontSize: "2.5rem",
      padding: "0.5rem 0",
      textAlign: "center",
    },
  },
  h41: {
    fontFamily: "inter",
    fontSize: "2rem",
    lineHeight: "1.5",
    textAlign: "left",
    fontWeight: "400",
    colors: "#fff",
    "@media (max-width: 600px)": {
      fontSize: "1rem",
      textAlign: "center",
    },
  },

  PFtext: {
    fontFamily: "Inter",
    fontSize: "1rem",
    margin: "1rem",
    textAlign: "center",
    fontWeight: "400",
    height: "100px",
    "@media (max-width: 600px)": {
      fontSize: "0.8rem",
      margin: "0.2rem",
      height: "120px",
    },
  },

  Resimg: {
    width: "100%",
    height: "auto",
  },

  carousalArrow: {
    fontSize: 40,
    color: "#B095FF",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.2)",
      transition: "all 0.5s ease",
    },
    "@media (max-width: 600px)": {
      fontSize: "1.5rem",
      marginBottom: "1rem",
    },
  },

  successName: {
    fontFamily: "inter",
    color: "#AE92FF",
    fontSize: "1.1rem",
    fontWeight: "400",
    padding: "0.5rem 1rem",
  },

  successDesc: {
    fontFamily: "inter",
    color: "#000",
    fontSize: "0.8rem",
    fontWeight: "400",
    padding: "0rem 1rem",
  },

  successStoriesDiv: {
    background: "#fff",
    borderRadius: "0.8rem",
    padding: "0.5rem",
    margin: "0.5rem 0rem",
  },

  itemDiv: {
    padding: "0rem 0.5rem",
    height: "270px",
    overflowY: "auto",
  },

  footerDesc: {
    fontFamily: "inter",
    color: "#fff",
    fontSize: "0.9rem",
    padding: "0.5rem 0rem 0rem 0rem",
    width: "70%",
    "@media (max-width: 600px)": {
      width: "100%",
    },
    "@media (min-width: 600px) and ( max-width: 1024px)": {
      padding: "1rem 0rem",
      width: "100%",
      textAlign: "center",
    },
  },

  footerHeading: {
    fontFamily: "inter",
    color: "#fff",
    fontSize: "1.2rem",
    fontWeight: "bold",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
    "@media (min-width: 600px) and ( max-width: 1024px)": {
      width: "100%",
      textAlign: "center",
    },
  },

  footerLink: {
    fontFamily: "inter",
    color: "#AE92FF",
    padding: "0.3rem 0rem",
    fontSize: "0.8rem",
    fontWeight: "400",
    textDecorationColor: "#AE92FF",
  },

  playstore: {
    width: "170px",
    height: "auto",
    padding: "0.3rem 0rem",
  },

  footerLink1: {
    fontFamily: "inter",
    color: "#fff",
    paddingRight: "1rem",
    fontSize: "0.8rem",
    fontWeight: "500",
    textDecoration: "none",
    borderRight: "1px solid #fff",
  },

  footerLink2: {
    fontFamily: "inter",
    color: "#fff",
    paddingLeft: "1rem",
    fontSize: "0.8rem",
    fontWeight: "500",
    textDecoration: "none",
  },

  socialMediaText: {
    fontFamily: "inter",
    color: "#fff",
    fontSize: "0.8rem",
    fontWeight: "500",
  },

  inputfieldDiv: {
    border: "1px solid #fff",
    borderRadius: "0.5rem",
    padding: "0.5rem",
    display: "flex",
    justifyContent: "space-between",
    mt: 2,
    width: "70%",
    "@media (max-width:600px)": {
      width: "100%",
    },
    "@media (min-width: 600px) and ( max-width: 1024px)": {
      width: "90%",
      margin: "auto",
    },
  },

  inputfield: {
    border: "none",
    outline: "none",
    width: "90%",
    background: "transparent",
    color: "#fff",
  },

  socialMediaIcon: {
    padding: "0rem 0.5rem",
    width: "40px",
  },

  footerCopyRight: {
    fontFamily: "inter",
    color: "#fff",
    fontSize: "0.7rem",
    fontWeight: "400",
    textAlign: "center",
    padding: "2rem 0rem",
  },

  footerCopyRightLink: {
    fontFamily: "inter",
    color: "#AE92FF",
    fontSize: "0.7rem",
    fontWeight: "400",
    padding: "0rem 0.5rem",
    textDecorationColor: "#AE92FF",
  },

  footerCopyRightLink1: {
    fontFamily: "inter",
    color: "#AE92FF",
    fontSize: "0.7rem",
    fontWeight: "400",
    padding: "0rem 0.5rem",
    textDecorationColor: "#AE92FF",
    borderRight: "1px solid #AE92FF",
  },

  submitBtn: {
    background: "transparent",
    outline: "none",
    border: "none",
    color: "#fff",
    cursor: "pointer",
  },

  leftshadows: {
    background: "linear-gradient(180deg, #A110CF 0%, #0753C6 100%)",
    filter: "blur(65px)",
    position: "absolute",
    width: "500px",
    height: "500px",
    left: "-50px",
    borderRadius: "50px",
    zIndex: "0",
    "@media (max-width: 1024px)": {
      width: "100px",
      height: "100px",
    },
  },

  shadow: {
    background: "linear-gradient(180deg, #A110CF 0%, #0753C6 100%)",
    filter: "blur(65px)",
    position: "absolute",
    width: "100%",
    height: "500px",
    left: "-50px",
    borderRadius: "50px",
    "@media (max-width: 1024px)": {
      width: "100px",
      height: "100px",
    },
  },

  worksText: {
    fontFamily: "inter",
    fontSize: "1rem",
    fontWeight: "400",
    padding: "0rem 0.5rem",
    color: "#261E45",
  },

  worksMainDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: "90%",
  },

  worksTextDiv: {
    border: "1px solid #fff",
    marginTop: "1rem",
    marginLeft: "-2rem",
    background: "#fff",
    padding: "1.8rem",
    borderRadius: "0.5rem",
  },

  numberDiv: {
    marginTop: "1rem",
    background: "#261E45",
    color: "#fff",
    padding: "0.5rem 0.5rem",
    width: "fit-content",
    float: "right",
    position: "absolute",
    right: "-5px",
    bottom: "-15px",
    borderRadius: "0.5rem 0rem",
  },
  numberSubDiv: {
    fontFamily: "inter",
    fontWeight: "400",
    backgroundColor: "#A056FD",
    padding: "0.5rem 1rem",
    borderRadius: "0.5rem",
    boxShadow: "0px 4px 4px 0px #AE92FF inset",
    dropShadow: "0px 4px 4px 0px #000",
    "&::after": {
      borderBottomRightRadius: "1.25em",
      boxShadow: "1.3em 1.3em 1.3em #261e45",
    },
  },

  BannerMainDiv: {
    position: "relative",
    zIndex: 1,
    "@media (min-width: 600px) and ( max-width: 1024px)": {
      overflowX: "hidden",
    },

    "@media (max-width: 600px)": {
      overflowX: "hidden",
    },
  },

  Banner1: {
    position: "absolute",
    right: "-5%",
    top: 0,
    zIndex: 0,
    "@media (min-width: 600px) and ( max-width: 1024px)": {
      display: "none",
    },
    "@media (max-width: 600px)": {
      display: "block",
      width: "200px",
      right: "0%",
    },
  },

  Banner2: {
    position: "absolute",
    top: "25%",
    right: "0%",
    zIndex: 0,
    "@media (min-width: 600px) and ( max-width: 1024px)": {
      display: "none",
    },
    "@media (max-width: 600px)": {
      display: "block",
      width: "200px",
      right: "0%",
    },
  },

  Banner3: {
    position: "absolute",
    top: "50%",
    right: "10%",
    zIndex: 0,
    "@media (min-width: 600px) and ( max-width: 1024px)": {
      display: "none",
    },
    "@media (max-width: 600px)": {
      display: "block",
      right: "0%",
      width: "100px",
    },
  },

  Banner4: {
    position: "absolute",
    bottom: "-10%",
    right: "-20%",
    zIndex: 0,
    "@media (min-width: 600px) and ( max-width: 1024px)": {
      display: "none",
    },
    "@media (max-width: 600px)": {
      display: "none",
    },
  },

  Banner5: {
    position: "absolute",
    bottom: "-10%",
    left: "-10%",
    zIndex: -1,
    "@media (max-width: 1024px)": {
      display: "none",
    },
  },

  spacerDiv: {
    height: "100px",
    "@media (max-width: 600px)": {
      height: "1rem",
    },
  },

  containerbg: {
    backgroundImage: `url("data:image/svg+xml,%3Csvg%20width%3D%221312%22%20height%3D%22610%22%20viewBox%3D%220%200%201312%20610%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M1056.06%20222.818C940.403%20269.386%20799.427%20290.078%20698.093%20290.078H15.5156V289.078H698.093C799.323%20289.078%20940.168%20268.402%201055.69%20221.891C1171.25%20175.362%201261.24%20103.084%201261.24%200H1262.24C1262.24%20103.757%201171.68%20176.269%201056.06%20222.818Z%22%20fill%3D%22%23AE92FF%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M1051.28%20386.693C934.027%20340.128%20793.053%20319.435%20698.09%20319.435H0V320.435H698.09C792.944%20320.435%20933.791%20341.11%201050.91%20387.623C1109.47%20410.879%201162.06%20440.583%201200.01%20477.364C1237.95%20514.138%201261.23%20557.97%201261.23%20609.513H1262.23C1262.23%20557.635%201238.79%20513.559%201200.7%20476.646C1162.63%20439.74%201109.9%20409.976%201051.28%20386.693Z%22%20fill%3D%22%23AE92FF%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M1019.45%20329.546C908.69%20312.954%20793.008%20309.434%20698.09%20309.434H0V310.434H698.09C792.989%20310.434%20908.614%20313.954%201019.31%20330.535C1130.01%20347.118%201235.72%20376.756%201310.84%20428.955L1311.41%20428.134C1236.1%20375.803%201130.21%20346.136%201019.45%20329.546Z%22%20fill%3D%22%23AE92FF%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M1019.45%20278.932C908.69%20295.524%20793.008%20299.044%20698.09%20299.044H0V298.044H698.09C792.989%20298.044%20908.614%20294.524%201019.31%20277.943C1130.01%20261.361%201235.72%20231.722%201310.84%20179.523L1311.41%20180.345C1236.1%20232.676%201130.21%20262.342%201019.45%20278.932Z%22%20fill%3D%22%23AE92FF%22%2F%3E%3C%2Fsvg%3E")`,
    backgroundSize: "contain",
    backgroundPosition: "130% 0%",
    backgroundRepeat: "no-repeat",
    position: "relative",
    zIndex: 1,
    "@media (max-width: 600px)": {
      backgroundImage: "none",
    },
  },

  successImg: {
    width: "50px",
    height: "50px",
    borderRadius: "25px",
    objectFit: "contain",
  },

  simpleTextDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "left",
    flexDirection: "column",
  },

  mobileCaptionDiv: {
    display: { xs: "block", md: "none" },
    borderRadius: "0.5rem",
    background: "#7150D0",
    padding: "0.4rem",
  },

  desktopCaptionDiv: {
    display: { xs: "none", md: "block" },
  },

  socialMediaLinkDiv: {
    pt: 3,
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 600px)": {
      pt: 1,
      flexDirection: "row",
      justifyContent: "space-around",
    },
    "@media (min-width: 600px) and ( max-width: 1024px)": {
      pt: 1,
      flexDirection: "row",
      justifyContent: "space-around",
    },
  },

  miniLogo: {
    textAlign: "left",
    "@media (max-width: 600px)": {
      pt: 2,
      textAlign: "center",
    },
    "@media (min-width: 600px) and ( max-width: 1024px)": {
      pt: 0,
      textAlign: "center",
    },
  },

  footerLinkDiv: {
    pt: 2,
    display: "flex",
    "@media (min-width: 600px) and ( max-width: 1024px)": {
      justifyContent: "center",
      alignItems: "center",
    },
  },

  playstoreDiv: {
    pt: 1,
    "@media (min-width: 600px) and ( max-width: 1024px)": {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
  },
};
