import React from 'react';
import { Doughnut, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';


ChartJS.register(ArcElement, Tooltip, Legend);

export const DonutChart = ({ Reportdata }) => {
  let labelData = [];
  let countData = [];
  for (let i = 0; i < Reportdata?.length; i++) {
    labelData.push(Reportdata[i].name);
    countData.push(Reportdata[i].userCount);
  }
  const data = {
    labels: labelData,
    datasets: [
      {
        label: '# of Votes',
        data: countData,
        backgroundColor: [
          '#BF0071',
          '#FF903F',
          '#8B15FF',
        ],
        borderColor: [
          '#BF0071',
          '#FF903F',
          '#8B15FF',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    cutout: '75%',
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: '#fff'
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.label + ': ' + tooltipItem.raw;
          },
        },
      },
    },
  };

  return <Doughnut data={data} options={options} />;
};


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const BarGraph = ({ graphData }) => {
  const data = {
    //Label
    labels: graphData.resultKey,
    datasets: [
      {
        label: '',
        //Data
        data: graphData.resultValue,
        backgroundColor: '#7150D0',
        borderColor: '#7150D0',
        borderWidth: 2,
        barThickness: 40,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      title: {
        display: false,
        text: 'Sales and Expenses Bar Chart',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        border: {
          display: false,
          color: "#fff",
        },
        ticks: {
          color: '#fff', // Set the color of the x-axis labels
        },
        grid: {
          display: true, // Hide the grid lines if you only want to show the axis line
          color: 'rgb(255, 255, 255, 0.2)',
          borderDash: [5, 5],
        },
      },
      x: {
        beginAtZero: true,
        border: {
          display: false,
          color: "#fff",
        },
        ticks: {
          color: '#fff', // Set the color of the x-axis labels
        },
        grid: {
          display: false, // Hide the grid lines if you only want to show the axis line
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

