import React from 'react'
import { Box } from '@mui/material';
import { rootLayoutStyles, commonStyles } from '../Styles/commonStyles';
import { useLocation } from 'react-router-dom';
import Notification from '../Utils/notification';


const Header = () => {
  const locationData = useLocation();
  return (
    <>
      <Box sx={rootLayoutStyles.headerRoot}>
        {locationData.pathname.slice(7).replace(/([a-z])([A-Z])/g, '$1 $2')}
        <Notification sx={commonStyles.mx1} />
      </Box>
    </>
  )
}

export default Header
