import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Image } from '../Utils/imageImport';
import { commonStyles, sidenavStyles } from '../Styles/commonStyles';
import { Icons } from '../Styles/Icons';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { CommonModel } from '../Utils/model';

const SideNav = () => {

  const locationData = useLocation();
  const [logoutModel, setLogoutModel] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    setLogoutModel(false);
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("adminId");
    sessionStorage.removeItem("routeAccess");
    navigate('/login');
  }

  const activeclass = (path) =>
    locationData.pathname === path ? sidenavStyles.activeDiv : sidenavStyles.notActiveDiv;

  const activeText = (path) =>
    locationData.pathname === path ? sidenavStyles.activeText : sidenavStyles.notActiveText;

  const NavItem = ({ to, Icon, label }) => (
    <NavLink to={to}>
      <Box sx={activeclass(to)}>
        <Icon />
        <Typography sx={activeText(to)}>{label}</Typography>
      </Box>
    </NavLink>
  );

  return (
    <>
      <CommonModel open={logoutModel} setOpen={setLogoutModel} status={4} handleRemoveApi={handleLogout} />
      <Box sx={[commonStyles.textCenter]}>
        <img src={Image.sideNav_logo} alt="logo" />
      </Box>

      <NavItem to="/Admin/Dashboard" Icon={Icons.DashboardIcon} label="Dashboard" />

      <NavItem to="/Admin/UserManagement" Icon={Icons.PeopleIcon} label="Users Management" />

      <NavItem to="/Admin/Report" Icon={Icons.ReportIcon} label="Reports" />

      <NavItem to="/Admin/ContentManagement" Icon={Icons.RateReviewIcon} label="Content Management" />

      <NavItem to="/Admin/ConnectionPreferences" Icon={Icons.PublicIcon} label="Connection Preferences" />

      <NavItem to="/Admin/Help&Support" Icon={Icons.HelpOutlineIcon} label="Help & Support" />

      <NavItem to="/Admin/Subscriber" Icon={Icons.NewspaperRoundedIcon} label="News Letter Subscriber" />

      <Button sx={sidenavStyles.logOutBtn}
        onClick={() => { setLogoutModel(true) }}>
        <Icons.LogoutIcon />
        <Typography sx={sidenavStyles.logoutText}>Logout </Typography>
      </Button>
    </>
  )
}

export default SideNav
