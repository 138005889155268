import React, { useState, useEffect } from 'react';
import { commonStyles } from '../../Styles/commonStyles';
import { Box, Typography, Tab, Tabs, Button } from '@mui/material';
import { AccordionUsage, Heading } from '../../Utils/customField';
import { Editor } from '../../Utils/editor';
import { commonServices } from '../../Services/commonServices';
import { toast } from 'react-toastify';
import { CommonModel } from '../../Utils/model';
import { Loader } from '../../Components/lotties';

const ContentManagement = () => {
    const [open, setOpen] = useState(false);
    const [tabStatus, setTabStatus] = useState(1);
    const [FaqQuestion, setFaqQuestion] = useState('');
    const [FaqAnswer, setFaqAnswer] = useState('');
    const [loader, setLoader] = useState(true);
    //1-Add FAQ, 2-Edit FAQ
    const [status, setStatus] = useState(1);
    //Edit & Delete Id
    const [id, setId] = useState('');
    //Tab HandleChanger
    const handleChange = (event, newValue) => {
        setTabStatus(newValue);
    };

    // get Privacy Policy Data
    const [privacy, setPrivacy] = useState('');
    const getPrivacyData = async () => {
        const res = await commonServices.privacyPolicy();
        if (res?.status === 200) {
            setTimeout(() => {
                setLoader(false);
            }, 500)
            setPrivacy(res?.data?.data?.description);
        }
    }

    // get Terms & Conditions
    const [terms, setTerms] = useState('');
    const getTermsData = async () => {
        const res = await commonServices.termsAndConditions();
        if (res?.status === 200) {
            setTimeout(() => {
                setLoader(false);
            }, 500)
            setTerms(res?.data?.data?.description);
        }
    }

    //get cookie policy
    const [cookie, setCookie] = useState('');
    const getCookieData = async () => {
        const res = await commonServices.cookiePolicy();
        if (res?.status === 200) {
            setTimeout(() => {
                setLoader(false);
            }, 500)
            setCookie(res?.data?.data?.description);
        }
    }

    //get faq data
    const [faqData, setFaqData] = React.useState([]);
    const getFaqData = async () => {
        let payload = {
            "pageNumber": 1,
            "pageSize": 10
        }
        const res = await commonServices.getFAQs(payload);
        if (res?.status === 200) {
            setTimeout(() => {
                setLoader(false);
            }, 500)
            setFaqData(res?.data?.data?.rows);
        }
    }
    //add faq data
    const addFaqFunc = async () => {
        if (FaqQuestion === '' || FaqAnswer === '') {
            toast.error("Please fill all the Required fields", commonStyles.toastError);
            return;
        }
        else if (status === 1) // Add New FAQ
        {
            {
                let payload = {
                    "question": FaqQuestion,
                    "answer": FaqAnswer
                }
                const res = await commonServices.addFAQ(payload);
                if (res?.status === 200) {
                    setFaqQuestion('');
                    setFaqAnswer('');
                    toast.success(res?.data?.message, commonStyles.toastSuccess);
                    getFaqData();
                }
            }
        }
        else if (status === 2) { // Edit FAQ
            let payload = {
                "question": FaqQuestion,
                "answer": FaqAnswer,
                "faqId": id
            }
            const res = await commonServices.editFAQ(payload);
            if (res?.status === 200) {
                setFaqQuestion('');
                setFaqAnswer('');
                toast.success(res?.data?.message, commonStyles.toastSuccess);
                getFaqData();
            }
        }
    }
    //Edit faq data
    const editFaqFunc = (data) => {
        setId(data?.id);
        setFaqQuestion(data?.question);
        setFaqAnswer(data?.answer);
        setStatus(2);
    }
    //Delete faq Function
    const deleteFaqFunc = async (data) => {
        console.log(data);
        setOpen(true);
        setId(data?.id);
    }
    //Delete FAQ API
    const deleteFAQApi = async () => {
        let payload = {
            "id": id
        }
        const res = await commonServices.deleteFAQ(payload);
        if (res?.status === 200) {
            toast.success(res?.data?.message, commonStyles.toastSuccess);
            setOpen(false);
            getFaqData();
        }
    }

    //Privacy API
    const privayApi = async (data) => {
        let payload = {
            "description": data === 1 ? terms : data === 2 ? cookie : privacy,
            type: data
        }
        const res = await commonServices.updatePrivacyPolicy(payload);
        if (res?.status === 200) {
            toast.success(res?.data?.message, commonStyles.toastSuccess);
            getFaqData();
        }
    }

    useEffect(() => {
        getFaqData();
        getPrivacyData();
        getTermsData();
        getCookieData();
    }, [])

    return (
        <>
            <Box sx={commonStyles.outlet_root}>
                <Heading title={'Content Management'} />
                {loader && <Loader />}
                <Tabs
                    value={tabStatus}
                    onChange={handleChange}
                >
                    <Tab value={1} label="FAQ" />
                    <Tab value={2} label="Privacy Policy" />
                    <Tab value={3} label="Terms & Conditions" />
                    <Tab value={4} label="Cookie Policy" />
                </Tabs>
                <CommonModel open={open} setOpen={setOpen} status={2} handleRemoveApi={deleteFAQApi} />

                {tabStatus === 1 &&
                    <>
                        <Box sx={commonStyles.flex}>
                            <Box sx={commonStyles.w50}>
                                {faqData.length === 0 &&
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: '3rem' }}>
                                        <Typography variant="h6" sx={commonStyles.faqEmpty}>
                                        FAQ section is empty. Start by adding a question to help others!
                                        </Typography>
                                    </Box>
                                }
                                <AccordionUsage faqData={faqData} editFaqFunc={editFaqFunc} deleteFaqFunc={deleteFaqFunc} />
                            </Box>
                            <Box sx={commonStyles.w50}>
                                <Typography variant="h6" sx={commonStyles.subHeading}>Add New</Typography>
                                <Typography variant="h6" sx={commonStyles.subHeading2}>Question</Typography>
                                <Box sx={commonStyles.editorBox}>
                                    <Editor data={FaqQuestion} setData={setFaqQuestion} tabStatus={tabStatus} />
                                </Box>
                                <Typography variant="h6" sx={commonStyles.subHeading2}>Answer</Typography>
                                <Box sx={commonStyles.editorBox}>
                                    <Editor data={FaqAnswer} setData={setFaqAnswer} tabStatus={tabStatus} />
                                </Box>
                                <Button sx={commonStyles.submitButton2} onClick={addFaqFunc}>
                                    {status === 1 ? 'Add' : 'Update'}
                                </Button>
                            </Box>
                        </Box>
                    </>
                }
                {/* 1 - Terms & Conditions */}
                {/* 2 - Cookie Policy */}
                {/* 3 - Privacy Policy */}
                {tabStatus === 2 &&
                    <>
                        <Box sx={commonStyles.ppmainDiv}>
                            <Editor data={privacy} setData={setPrivacy} tabStatus={tabStatus} />
                        </Box>
                        <Button sx={commonStyles.submitButton2} onClick={() => { privayApi(3) }}>SAVE</Button>
                    </>
                }

                {tabStatus === 3 && <>
                    <Box sx={commonStyles.ppmainDiv}>
                        <Editor data={terms} setData={setTerms} tabStatus={tabStatus} />
                    </Box>
                    <Button sx={commonStyles.submitButton2} onClick={() => { privayApi(1) }}> SAVE </Button>
                </>}

                {tabStatus === 4 && <>
                    <Box sx={commonStyles.ppmainDiv}>
                        <Editor data={cookie} setData={setCookie} tabStatus={tabStatus} />
                    </Box>
                    <Button sx={commonStyles.submitButton2} onClick={() => { privayApi(2) }}> SAVE </Button>
                </>}


            </Box>
        </>
    )
}

export default ContentManagement
