import React from 'react';
import CommonRoutes from './commonRoutes'
import PrivateRoutes from './privateRoutes'

const routes = () => {
  return (
    <>
      <CommonRoutes />
      <PrivateRoutes />
    </>
  )
}

export default routes
