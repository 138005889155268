import React, { useEffect, useState } from 'react';
import { commonStyles, TableStyles } from '../../Styles/commonStyles';
import { commonServices } from '../../Services/commonServices';
import { Heading } from '../../Utils/customField';
import { Box, TableBody, TableCell, TableRow, Table, TableContainer, Paper, Typography } from '@mui/material';
import BasicTable from '../../Utils/Table';
import { dateFormat } from '../../Utils/functions';
import { ImageTitle, TablePagination } from '../../Utils/customField';
import { Loader, NoData } from '../../Components/lotties';

const HelpSupport = () => {
    const TableHeadRow = ['S.No', 'User Name', 'Subject', 'Date', 'Message'];
    const [helpData, setHelpData] = useState([]);
    //Table Search, Pagination & Loader
    const [tablePagination, setTablePagination] = useState({
        totalPages: 0,
        currentPage: 1,
        search: '',
        loader: true,
    });

    const getHelp = async () => {
        setTablePagination((prev) => ({ ...prev, loader: true }));
        let payload = {
            pageNumber: tablePagination.currentPage,
            pageSize: 10,
        }
        const res = await commonServices.getHelp(payload);
        if (res?.status === 200) {
            setHelpData(res?.data?.data?.rows);
            setTimeout(() => {
                setTablePagination((prev) => ({ ...prev, loader: false }));
            }, 500)
            const totalPages = Math.ceil(res?.data?.data?.count / 10);
            setTablePagination((prev) => ({ ...prev, totalPages: totalPages }));
        }
    }

    useEffect(() => {
        getHelp();
    }, [tablePagination.currentPage])
    return (
        <>
            <Box sx={commonStyles.outlet_root}>
                {tablePagination.loader && <Loader />}
                <Heading title="Help & Support" />
                {helpData.length !== 0 && helpData ?
                    <>
                        <TableContainer component={Paper} sx={TableStyles.tableContainer}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <BasicTable TableHeadRow={TableHeadRow} />
                                <TableBody sx={TableStyles.tableBody}>
                                    {helpData.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell sx={TableStyles.tableCell}>{index + 1}</TableCell>
                                                <TableCell sx={[TableStyles.tableCell]}>
                                                    <ImageTitle url={row?.userProfilePic} title={row?.userName} />
                                                </TableCell>
                                            <TableCell sx={{ maxWidth: '200px' }}>
                                                <Typography sx={TableStyles.textWrap}>
                                                    {row.subject}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={TableStyles.tableCell}>{dateFormat(row.createdAt)}</TableCell>
                                            <TableCell sx={{ maxWidth: '200px' }}>
                                                <Typography sx={TableStyles.textWrap}>
                                                    {row.description}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination tablePagination={tablePagination} setTablePagination={setTablePagination} />
                    </> : <NoData />}
            </Box >
        </>
    )
}

export default HelpSupport
