import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../Pages/Admin/login'; 
import ForgotPassword from '../Pages/Admin/forgotPassword';
import ResetPassword from '../Pages/Admin/resetPassword';
import PrivacyPolicy from '../Pages/User/privacyPolicy';
import TermsConditions from '../Pages/User/termsConditions';
import CookiePolicy from '../Pages/User/cookiePolicy';
import DeleteUser from '../Pages/User/deleteUser';
import Otp from '../Pages/User/otp';
import Promo from '../Pages/Promo/index';

const CommonRoutes = () => {
  return (
    <div>
      <Routes>
        <Route index element={<Promo />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/ResetPassword/:token" element={<ResetPassword />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/TermsConditions" element={<TermsConditions />} />
        <Route path="/CookiePolicy" element={<CookiePolicy />} />
        <Route path="/DeleteUser" element={<DeleteUser />} />
        <Route path="/Otp" element={<Otp />} />
      </Routes>
    </div>
  )
}

export default CommonRoutes
