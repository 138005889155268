import React from "react";
import { promoStyles } from "../../Styles/promoStyles";
import { commonStyles } from "../../Styles/commonStyles";
import { promoImages } from "../../Utils/imageImport";
import UsrFrdCarousal from "./usrFrdCarousal";
import { motion } from "framer-motion";
import ReCAPTCHA from "react-google-recaptcha";
import { validateEmpty } from "../../Utils/validations";
import { toast } from "react-toastify";
import { commonServices } from "../../Services/commonServices";
import { ApiConfig } from "../../Config/config";
import {
  Box,
  Stack,
  Link,
  Typography,
  Grid,
  Paper,
  Container,
  styled,
  Tooltip,
} from "@mui/material";

const NavLink = ({ url, label, active }) => (
  <Link href={url} sx={active ? promoStyles.anchorActive : promoStyles.anchor}>
    {label}
  </Link>
);

const ScrollText = ({ title }) => {
  return (
    <Box sx={promoStyles.d_flex}>
      <Typography class="scrollText" style={promoStyles.d_flex}>
        <img src={promoImages.star} alt="Tune Connect Logo" className="star" />
        {title}
      </Typography>
    </Box>
  );
};

const PFGrid = ({ url, title }) => {
  return (
    <Grid item xs={6} sm={6} md={4} lg={3} xl={2} sx={{ zIndex: 1 }}>
      <Item>
        <Typography sx={promoStyles.PFtext}>{title}</Typography>
        <img src={url} alt="Tune Connect Logo" style={promoStyles.Resimg} />
      </Item>
    </Grid>
  );
};

const SuccesStories = ({ url, userName, userDesc }) => {
  return (
    <Box sx={promoStyles.d_flex_start}>
      <img src={url} alt="Tune Connect Logo" style={promoStyles.successImg} />
      <Box>
        <Typography sx={promoStyles.successName}>{userName}</Typography>
        <Typography sx={promoStyles.successDesc}>{userDesc}</Typography>
      </Box>
    </Box>
  );
};

const WorksDic = ({ text, step }) => {
  return (
    <Box sx={promoStyles.worksTextDiv} style={{ position: "relative" }}>
      <Typography variant="h4" sx={promoStyles.worksText}>
        {" "}
        {text}{" "}
      </Typography>
      <Box sx={promoStyles.numberDiv}>
        <Box sx={promoStyles.numberSubDiv}>{step}</Box>
      </Box>
    </Box>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  textAlign: "center",
  background:
    "linear-gradient(180deg, #AE92FF 0%, rgba(174, 146, 255, 0) 93.45%)",
  borderRadius: "1.5rem 1.5rem 0 0",
  boxShadow: "none",
  color: "#fff",
}));

const Item1 = styled(Paper)(({ theme }) => ({
  textAlign: "left",
  background: "transparent",
  boxShadow: "none",
  color: "#fff",
}));

const AnimateImageBottom = ({ url }) => {
  return (
    <motion.div
      initial={{ y: "100%" }}
      animate={{ y: "0%" }}
      transition={{ duration: 1 }}
    >
      <img src={url} alt="Tune Connect Logo" className="bannerImg" />
    </motion.div>
  );
};

const AnimateImageLeft = ({ url }) => {
  return (
    <motion.div
      initial={{ x: "-50%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 1 }}
    >
      <img src={url} alt="Tune Connect Logo" className="bannerImg" />
    </motion.div>
  );
};

const AnimateTextLeft = ({ text }) => {
  return (
    <motion.div
      initial={{ x: "-50%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 1 }}
    >
      {text}
    </motion.div>
  );
};

const Heading3 = ({ text }) => {
  return (
    <Typography variant="h3" sx={promoStyles.h32}>
      {text}
    </Typography>
  );
};

const Promo = () => {
  const onChange = async (value) => {
    setCaptchaStatus(true);
    const capthaRes = await commonServices.captchaVerification({
      captchaToken: value,
    });
    if (capthaRes?.status === 200) {
      toast.success(capthaRes?.data?.message, commonStyles.toastSuccess);
    }
  };

  const [capthaStatus, setCaptchaStatus] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState({
    email: "",
    captha: "",
  });
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
    });

    if (!validateEmpty(data.get("email"), "email", errorMsg, setErrorMsg))
      return;
    if (!capthaStatus) {
      setErrorMsg((prevState) => ({
        ...prevState,
        captha: "Please verify captcha",
      }));
      return false;
    }
    const loginRes = await commonServices.subscribeEmailValidation({
      email: data.get("email"),
    });
    if (loginRes?.status === 200) {
      toast.success(loginRes?.data?.message, commonStyles.toastSuccess);
      event.target.reset();
      //Refreshing the Captha
      setTimeout(() => {
        if (window.grecaptcha) {
          window.grecaptcha.reset();
        }
      }, 1000);
    }
  };
  return (
    <>
      <Box sx={promoStyles.promoDiv} id="home">
        {/* Large Device Logo Section */}
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Box sx={[promoStyles.d_flex_around, promoStyles.py2]}>
            <a
              href="https://play.google.com/store/apps/details?id=com.krify.tuneconnect"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={promoImages.playstore}
                alt="PlayStore Logo"
                style={promoStyles.playstore}
              />
            </a>
            <img
              src={promoImages.promoLogo}
              alt="logo"
              width={150}
              height={"auto"}
            />
            <a
              href="https://apps.apple.com/in/app/tuneconnect/id6602890730"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={promoImages.appstore}
                alt="App Logo"
                style={promoStyles.playstore}
              />
            </a>
          </Box>
        </Box>

        {/* Small Device Logo Section */}
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <Box sx={[promoStyles.d_flex_around, promoStyles.py2]}>
            <img
              src={promoImages.promoLogo}
              alt="logo"
              width={150}
              height={"auto"}
            />
          </Box>
        </Box>

        {/* Menu Links */}
        <Stack
          direction="row"
          spacing={{ xs: 0, sm: 4, md: 6, lg: 8 }}
          sx={[promoStyles.d_flex_center]}
        >
          <NavLink url="#home" label="Home" active={true} />
          <NavLink url="#about" label="About Us" active={false} />
          <NavLink url="#howItWorks" label="How It Works" active={false} />
          <NavLink url="#contact" label="Contact Us" active={false} />
        </Stack>

        {/* Spacer */}
        <Box sx={promoStyles.spacerDiv}></Box>

        {/* Banner */}
        <Box sx={promoStyles.shadow}></Box>
        <Box
          style={promoStyles.containerbg}
          sx={{ position: "relative", px: { xs: 1, sm: 5, md: 5, lg: 10 } }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              sx={promoStyles.BannerMainDiv}
            >
              <Box sx={promoStyles.Banner1}>
                <AnimateImageBottom url={promoImages.Banner2} />
              </Box>
              <Box sx={promoStyles.Banner2}>
                <AnimateImageBottom url={promoImages.Banner3} />
              </Box>
              <Box sx={promoStyles.Banner3}>
                <AnimateImageBottom url={promoImages.Banner4} />
              </Box>
              <Box sx={promoStyles.Banner4}>
                <AnimateImageLeft url={promoImages.Banner5} />
              </Box>
              <img
                src={promoImages.Banner1}
                alt="Tune Connect Logo"
                className="bannerImg"
              />
              <Box sx={promoStyles.Banner5}>
                <img
                  src={promoImages.Banner6}
                  alt="Tune Connect Logo"
                  className="bannerImg"
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={promoStyles.simpleTextDiv}
            >
              <Box sx={promoStyles.desktopCaptionDiv}>
                <Heading3 text="Where Music" />
                <Heading3 text="Meets Friendship" />
              </Box>
              <Box sx={promoStyles.mobileCaptionDiv}>
                <Heading3 text="Where Music Meets Friendship" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={promoStyles.spacerDiv}></Box>

        {/* Scroll Text */}
        <Box class="scrollDiv">
          <Box sx={promoStyles.d_flex_between}>
            <ScrollText title="DISCOVER NEW MUSIC" />
            <ScrollText title="SHARE YOUR FAVORITES" />
            <ScrollText title="CONNECT WITH OTHERS" />
          </Box>
        </Box>

        {/* Powerful Features */}
        <Typography variant="h3" sx={promoStyles.h3}>
          Powerful Features
        </Typography>
        <Box id="about">
          <Grid
            container
            spacing={1}
            sx={{
              position: "relative",
              zIndex: 1,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <PFGrid
              url={promoImages.Pf1}
              title="“Tuneconnect isn’t just about music it is about connecting with people who
            resonate with the same beats and melodies as you.”"
            />
            <PFGrid
              url={promoImages.Pf2}
              title="“Seamless integration with Spotify and other 
            platforms ensures you have access to all your favorite tunes.”"
            />
            <PFGrid
              url={promoImages.Pf3}
              title="“ Like a song? Leave a comment, like it, and 
            see who else enjoys it just as much as you do.”"
            />
            <PFGrid
              url={promoImages.Pf4}
              title="“Find friends through music, chat, and build your musical community with Tuneconnect.”"
            />
          </Grid>
        </Box>

        {/* User Friendly Interface */}
        <Typography variant="h3" sx={promoStyles.h3}>
          User Friendly Interface
        </Typography>
        <UsrFrdCarousal />

        {/* How it works */}
        <Typography variant="h3" sx={promoStyles.h3} id="howItWorks">
          <AnimateTextLeft text="How it works" />
        </Typography>
        <Container maxWidth="md" sx={{ position: "relative", zIndex: 1 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              sx={{ position: "relative", zIndex: 1 }}
            >
              <img src={promoImages.Works} alt="How it works" width={"100%"} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Box sx={promoStyles.worksMainDiv}>
                <WorksDic text={"Download app and Register"} step={1} />
                <WorksDic
                  text={
                    "Verify account, set social preferences and upload a selfie"
                  }
                  step={2}
                />
                <WorksDic
                  text={"List songs from Spotify. Like, comment and share song"}
                  step={3}
                />
                <WorksDic
                  text={
                    "Initiate chat with the people who have mutual taste and get connected"
                  }
                  step={4}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>

        {/* Success Stories */}
        <Typography id="success-stories" variant="h3" sx={{ display: "none" }}>
          Success Stories
        </Typography>
        <Container maxWidth="md" sx={{ display: "none" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box sx={promoStyles.leftshadows}></Box>
              <Item1 sx={{ position: "relative", zIndex: 1 }}>
                <Box sx={promoStyles.desktopCaptionDiv}>
                  <Typography variant="h4" sx={promoStyles.h41}>
                    Success stories{" "}
                  </Typography>
                  <Typography variant="h4" sx={promoStyles.h41}>
                    of people who{" "}
                  </Typography>
                  <Typography variant="h4" sx={promoStyles.h41}>
                    found their besties
                  </Typography>
                  <Typography variant="h4" sx={promoStyles.h41}>
                    through
                  </Typography>
                  <Typography variant="h3" sx={promoStyles.h31}>
                    “Tuneconnect”
                  </Typography>
                </Box>
                <Box sx={promoStyles.mobileCaptionDiv}>
                  <Typography variant="h4" sx={promoStyles.h41}>
                    Success stories of people who found their besties through
                    <b>“Tuneconnect”</b>{" "}
                  </Typography>
                </Box>
              </Item1>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Item1 sx={promoStyles.itemDiv}>
                <Box sx={promoStyles.successStoriesDiv}>
                  <SuccesStories
                    url={promoImages.S1}
                    userName="Anupamaa"
                    userDesc="Dil ko choo jane wala gaana, Arijit Singh ki awaaz kamaal hai."
                  />
                </Box>
                <Box sx={promoStyles.successStoriesDiv}>
                  <SuccesStories
                    url={promoImages.S2}
                    userName="Wilson Smith"
                    userDesc="Pyar aur dard ko badi khoobsurti se dikhata hai, bahut emotional."
                  />
                </Box>
                <Box sx={promoStyles.successStoriesDiv}>
                  <SuccesStories
                    url={promoImages.S3}
                    userName="Siddiq"
                    userDesc="Grand aur epic feel ke sath ek royal gaana."
                  />
                </Box>
                <Box sx={promoStyles.successStoriesDiv}>
                  <SuccesStories
                    url={promoImages.S4}
                    userName="Karim"
                    userDesc="Fun aur peppy track, instantly groove karne par majboor karta hai."
                  />
                </Box>
                <Box sx={promoStyles.successStoriesDiv}>
                  <SuccesStories
                    url={promoImages.S5}
                    userName="Sharuk "
                    userDesc="Emotional aur heart-touching, Arijit Singh ne kamaal kiya hai."
                  />
                </Box>
              </Item1>
            </Grid>
          </Grid>
        </Container>
        <Box sx={promoStyles.spacerDiv}></Box>

        {/* Footer Section */}
        <Container
          id="contact"
          sx={{
            position: "relative",
            zIndex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            {/* Input Section */}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box sx={promoStyles.miniLogo}>
                <img src={promoImages.footerLogo} alt="Tune Connect Logo" />
              </Box>
              <Typography sx={promoStyles.footerDesc}>
                Subscribe to Tuneconnect’s newsletter. Be the first to receive
                all latest posts in your inbox
              </Typography>

              <Box sx={{ pt: 1 }}>
                <ReCAPTCHA
                  sitekey={ApiConfig.recaptchaKey}
                  onChange={onChange}
                />
              </Box>

              <Box
                sx={promoStyles.inputfieldDiv}
                component={"form"}
                onSubmit={handleSubmit}
              >
                <input
                  name="email"
                  placeholder="Enter Your Email address"
                  className="footerinput"
                  style={promoStyles.inputfield}
                />
                <button style={promoStyles.submitBtn} type="submit">
                  Submit
                </button>
              </Box>
              {errorMsg.email && <p>{errorMsg.email}</p>}
              {errorMsg.captha && <p>{errorMsg.captha}</p>}

              <Box sx={promoStyles.footerLinkDiv}>
                <Link sx={promoStyles.footerLink1}>
                  Support@tuneconnectapp.com
                </Link>
                <Link sx={promoStyles.footerLink2}> +44 330 789 0958</Link>
              </Box>
              <Box sx={promoStyles.footerLinkDiv}>
                <Typography sx={promoStyles.socialMediaText}>
                  Connect with us
                </Typography>
                <a
                  href="https://www.facebook.com/tuneconnectapp"
                  target="_blank"
                >
                  <img
                    src={promoImages.facebook}
                    alt="facebook Icon"
                    style={promoStyles.socialMediaIcon}
                    rel="noopener noreferrer"
                  />
                </a>

                <a
                  href="https://www.instagram.com/tuneconnectapp/"
                  target="_blank"
                >
                  <img
                    src={promoImages.instagram}
                    alt="instagram Icon"
                    style={promoStyles.socialMediaIcon}
                    rel="noopener noreferrer"
                  />
                </a>

                <a
                  href="https://www.youtube.com/channel/UCrc-bW6AzMriu8WOfm78prw"
                  target="_blank"
                >
                  <img
                    src={promoImages.youtube}
                    alt="youtube Icon"
                    style={promoStyles.socialMediaIcon}
                    rel="noopener noreferrer"
                  />
                </a>
                {/* LinkedIn */}
                <a
                  href="https://www.linkedin.com/company/tuneconnectapp/"
                  target="_blank"
                >
                  <img
                    src={promoImages.LinkedIn}
                    alt="youtube Icon"
                    style={promoStyles.socialMediaIcon}
                    rel="noopener noreferrer"
                  />
                </a>
              </Box>
            </Grid>

            {/* Quick Links */}
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Typography variant="h4" sx={promoStyles.footerHeading}>
                Quick links
              </Typography>
              <Box sx={promoStyles.socialMediaLinkDiv}>
                <Link href="#home" sx={promoStyles.footerLink}>
                  Home
                </Link>
                <Link href="#about" sx={promoStyles.footerLink}>
                  About Us
                </Link>
                <Link href="#howItWorks" sx={promoStyles.footerLink}>
                  How It Works
                </Link>
                <Link href="#contact" sx={promoStyles.footerLink}>
                  Contact Us
                </Link>
              </Box>
            </Grid>

            {/* Download App */}
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <Typography variant="h4" sx={promoStyles.footerHeading}>
                Download App
              </Typography>
              <Box sx={promoStyles.playstoreDiv}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.krify.tuneconnect"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={promoImages.playstore}
                    alt="PlayStore Logo"
                    style={promoStyles.playstore}
                  />
                </a>

                <a
                  href="https://apps.apple.com/in/app/tuneconnect/id6602890730"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={promoImages.appstore}
                    alt="App Logo"
                    style={promoStyles.playstore}
                  />
                </a>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth="md">
          <Box>
            <Typography sx={promoStyles.footerCopyRight}>
              © 2024 Tuneconnect. All rights reserved.
              <Link
                href="/privacyPolicy"
                target="_blank"
                sx={promoStyles.footerCopyRightLink1}
              >
                Privacy Policy
              </Link>
              <Link
                href="/TermsConditions"
                target="_blank"
                sx={promoStyles.footerCopyRightLink}
              >
                Terms and Condition
              </Link>
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Promo;
