import React, { useEffect, useState } from 'react';
import { commonStyles, TableStyles } from '../../Styles/commonStyles';
import { TableBody, TableCell, TableRow, Table, TableContainer, Tab, Tabs } from '@mui/material';
import { Box, Typography, Paper, Tooltip } from '@mui/material';
import { SearchInputField, TablePagination, CustomTooltip, ToolTipContent, ImageTitle } from '../../Utils/customField';
import { commonServices } from '../../Services/commonServices';
import { dateFormat } from '../../Utils/functions';
import { Icons } from '../../Styles/Icons';
import { Warnmodel, Warnmodel2 } from '../../Utils/model';
import { toast } from 'react-toastify';
import { Loader, NoData } from '../../Components/lotties';
import BasicTable from '../../Utils/Table';
import { useLocation, useNavigate } from 'react-router-dom';


const Report = () => {
    const Navigate = useNavigate();
    const location = useLocation();
    console.log(location?.state?.tabStatusNew);
    const [tabStatus, setTabStatus] = useState(1);

    //Tab HandleChanger
    const handleChange = (event, newValue) => {
        setTabStatus(newValue);
    };
    const TableHeadRow = ['S.No', 'Reporter', 'Reportee', 'Reported Date', 'Reason', 'Actions'];
    const TableHeadRow1 = ['S.No', 'Name', 'Date', 'Reported List', 'Blocked List', 'Admin Warned Count', 'Actions'];
    const [tablePagination, setTablePagination] = useState({
        totalPages: 0,
        currentPage: 1,
        search: '',
        loader: true,
    });

    //Get Comment Report List API 
    const [getReportListData, setGetReportListData] = useState([]);
    const getReportList = async () => {
        let payload = {
            "pageNumber": tablePagination?.currentPage,
            "pageSize": 10,
        }
        const res = await commonServices.getReportList(payload);
        if (res?.status === 200) {
            setTimeout(() => {
                setTablePagination((prev) => ({ ...prev, loader: false }));
            }, [1000]);
            setGetReportListData(res?.data?.data?.rows);
            const totalPages = Math.ceil(res?.data?.data?.count / 10);
            setTablePagination((prev) => ({ ...prev, totalPages: totalPages }));
        }
    }

    const [tablePagination2, setTablePagination2] = useState(
        {
            totalPages: 0,
            currentPage: 1,
            search: '',
        }
    );
    //Get Chat Report List API
    const [reportedblockUserData, setReportedblockUserData] = useState([]);
    const reportedblockUserCount = async () => {
        let payload = {
            "pageNumber": tablePagination2?.currentPage,
            "pageSize": 10,
            "search": tablePagination2?.search
        }
        const res = await commonServices.reportedblockUserCount(payload)
        if (res?.status === 200) {
            console.log(res?.data?.data?.rows);
            setReportedblockUserData(res?.data?.data?.rows);
            const totalPages = Math.ceil(res?.data?.data?.count / 10);
            setTablePagination2((prev) => ({ ...prev, totalPages: totalPages }));
        }
    }

    useEffect(() => {
        getReportList();
        reportedblockUserCount();
        if(location?.state?.tabStatusNew===2){
            setTabStatus(2);
        }
    }, [tablePagination?.currentPage, tablePagination2?.currentPage]);

    const tooltipFun = (value) => {
        return (
            <>
                <ToolTipContent heading="Song Name: " title={value.songName} />
                <ToolTipContent heading="Comment: " title={value.comment} />
                <ToolTipContent heading="User Description: " title={value.reporterUserDescription} />
                <ToolTipContent heading="Admin Description: " title={value.adminDescription} />
            </>
        )
    }

    const handleSearch = async (e) => {
        setTablePagination((prev) => ({ ...prev, search: e.target.value }));


        let payload = {
            "pageNumber": tablePagination?.currentPage,
            "pageSize": 10,
            "search": e.target.value,
        }

        if (tabStatus === 1) {
            const res = await commonServices.getReportList(payload);
            if (res?.status === 200) {
                console.log(res?.data?.data?.rows);
                setGetReportListData(res?.data?.data?.rows);
                const totalPages = Math.ceil(res?.data?.data?.count / 10);
                setTablePagination((prev) => ({ ...prev, totalPages: totalPages }));
            }
        }
        else if (tabStatus === 2) {
            const res = await commonServices.reportedblockUserCount(payload);
            if (res?.status === 200) {
                console.log(res?.data?.data?.rows);
                setReportedblockUserData(res?.data?.data?.rows);
                const totalPages = Math.ceil(res?.data?.data?.count / 10);
                setTablePagination2((prev) => ({ ...prev, totalPages: totalPages }));
            }
        }
    }

    //Handle Warn the User API
    //Warm Status - 2
    const [modelOpen, setModelOpen] = useState(false);
    const [warnUserData, setWarnUserData] = useState({
        commentReportId: '',
        status: 0,
        description: '',
    });

    //Handle Warn the User API - Comment Report
    const handleWarnFun = (data) => {
        setWarnUserData((prev) => ({ ...prev, commentReportId: data.commentReportId, status: 2, description: '' }));
        setModelOpen(true);
    }

    //Handle Block the User API - Comment Report
    //Block Status - 1
    const handleBlockFun = (data) => {
        setWarnUserData((prev) => ({ ...prev, commentReportId: data.commentReportId, status: 1, description: '' }));
        setModelOpen(true);
    }

    const handleInputField = (e) => {
        setWarnUserData((prev) => ({ ...prev, description: e.target.value }));
    }

    // Warn & Block API - Comment Report
    const handleWarnApi = async () => {
        setTablePagination((prev) => ({ ...prev, loader: true }));
        const res = await commonServices.updateCommentReport(warnUserData);
        if (res?.status === 200) {
            setInterval(() => {
                setTablePagination((prev) => ({ ...prev, loader: false }));
            }, 2000);
            setModelOpen(false);
            getReportList();
            setWarnUserData((prev) => ({ ...prev, description: '' }));
            toast.success(res?.data?.message, commonStyles.toastSuccess);
        }
    }

    //Handle Warn & Block the User API - Chat Report
    //Block & Warn Status - 1 & 2
    const [chatReportComment, setChatReportComment] = useState({
        userId: '',
        type: 0,
        description: '',
    })

    const handleWarnFun1 = (data) => {
        setChatReportComment((prev) => ({ ...prev, userId: data.userId, type: 2, description: '' }));
        setModelOpen(true);
    }

    const handleBlockFun1 = (data) => {
        setChatReportComment((prev) => ({ ...prev, userId: data.userId, type: 1, description: '' }));
        setModelOpen(true);
    }

    const handleInputField1 = (e) => {
        setChatReportComment((prev) => ({ ...prev, description: e.target.value }));
    }

    // Warn & Block API - Comment Report
    const handleWarnApi1 = async () => {
        setTablePagination((prev) => ({ ...prev, loader: true }));
        console.log(chatReportComment);
        const res = await commonServices.chatReportBlocks(chatReportComment);
        if (res?.status === 200) {
            setInterval(() => {
                setTablePagination((prev) => ({ ...prev, loader: false }));
            }, 2000);
            setModelOpen(false);
            reportedblockUserCount();
            setWarnUserData((prev) => ({ ...prev, description: '' }));
            toast.success(res?.data?.message, commonStyles.toastSuccess);
        }
    }

    //Handle Report API & Navigations
    const handleReportAPI = (userId, type, count) => {
        if (count === '0') {
            toast.error('No Report Found', commonStyles.toastError);
            return false;
        }
        else {
            Navigate('/Admin/Nav/ReportListDetails', { state: { userId, type } });
        }
    }

    //Handle Block API & Navigations
    const handleBlockAPI = (userId, type, count) => {
        if (count === '0') {
            toast.error('No Report Found', commonStyles.toastError);
            return false;
        }
        else {
            Navigate('/Admin/Nav/ReportListDetails', { state: { userId, type } });
        }
    }

    //Handle Admin Count View 
    const handleAdminCount = (userId, type, count) => {
        console.log(userId, type, count);
        if (count === '0') {
            toast.error('No Report Found', commonStyles.toastError);
            return false;
        }
        else {
            Navigate('/Admin/Nav/ReportListDetails', { state: { userId, type } });
        }
    }

    return (
        <Box sx={commonStyles.outlet_root}>
            <Box sx={commonStyles.flex_center_between}>
                <Tabs
                    value={tabStatus}
                    onChange={handleChange}
                >
                    <Tab value={1} label="Comment Reports" />
                    <Tab value={2} label="Chat Reports" />
                </Tabs>
                <SearchInputField tablePagination={tablePagination} handleSearch={handleSearch} />
                <Warnmodel
                    open={modelOpen} setOpen={setModelOpen} warnUserData={warnUserData}
                    handleInputField={handleInputField} handleWarnApi={handleWarnApi} />
            </Box>
            {tablePagination.loader && <Loader />}

            {tabStatus === 1 ?
                getReportListData?.length !== 0 && getReportListData ?
                    <>
                        <TableContainer component={Paper} sx={TableStyles.tableContainer}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <BasicTable TableHeadRow={TableHeadRow} />
                                <TableBody>
                                    {getReportListData?.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell sx={TableStyles.tableCell}>{index + 1}</TableCell>
                                            <TableCell sx={TableStyles.tableCell}>
                                                <ImageTitle url={row?.reporterUserProfilePic} title={row?.reporterUserName} />
                                            </TableCell>
                                            <TableCell sx={TableStyles.tableCell} style={{ textAlign: 'center' }}>
                                                <ImageTitle url={row?.reportedUserProfilePic} title={row?.reportedUserName} />
                                            </TableCell>
                                            <TableCell sx={TableStyles.tableCell}>{dateFormat(row?.createdAt)}</TableCell>
                                            <TableCell sx={TableStyles.tableCell}>
                                                <Tooltip title={tooltipFun(row)} placement="bottom" arrow>
                                                    <Typography component={"li"} sx={TableStyles.reasonText}>
                                                        {row?.reportReasonReason}
                                                    </Typography>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell sx={TableStyles.tableCell}>
                                                {row?.status === 1 && <Typography sx={commonStyles.warnText}>Blocked</Typography>}
                                                {row?.status === 2 && <Typography sx={commonStyles.blockText}>Warned</Typography>}
                                                {row?.status === 0 &&
                                                    <Box>
                                                        <CustomTooltip title="Warn the User">
                                                            <Icons.ErrorOutlineIcon sx={commonStyles.warnIcon}
                                                                onClick={() => { handleWarnFun(row) }}
                                                            />
                                                        </CustomTooltip>
                                                        <CustomTooltip title="Block the User">
                                                            <Icons.BlockIcon sx={commonStyles.blockIcon}
                                                                onClick={() => { handleBlockFun(row) }} />
                                                        </CustomTooltip>
                                                    </Box>}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination tablePagination={tablePagination} setTablePagination={setTablePagination} />
                    </>
                    :
                    <NoData />
                :
                <>
                    <Warnmodel2 open={modelOpen} setOpen={setModelOpen} warnUserData={chatReportComment}
                        handleInputField={handleInputField1} handleWarnApi={handleWarnApi1} />
                    {reportedblockUserData?.length !== 0 && reportedblockUserData ?
                        <>
                            <TableContainer component={Paper} sx={TableStyles.tableContainer}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <BasicTable TableHeadRow={TableHeadRow1} />
                                    <TableBody>
                                        {reportedblockUserData?.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell sx={TableStyles.tableCell}> {index + 1} </TableCell>
                                                <TableCell sx={TableStyles.tableCell}>
                                                    <ImageTitle url={row?.profilePic} title={row?.name} />
                                                </TableCell>
                                                <TableCell sx={TableStyles.tableCell}> {dateFormat(row.reportedDate)} </TableCell>
                                                <TableCell sx={[TableStyles.tableCell, TableStyles.pointer]}
                                                    onClick={() => { handleReportAPI(row.userId, 2, row.reportedUsers) }}>
                                                    <CustomTooltip title="View Reported List">
                                                        {row.reportedUsers}
                                                    </CustomTooltip>
                                                </TableCell>
                                                <TableCell sx={[TableStyles.tableCell, TableStyles.pointer]}
                                                    onClick={() => { handleBlockAPI(row.userId, 1, row.blockedUsers) }}>
                                                    <CustomTooltip title="View Blocked List">
                                                        {row.blockedUsers}
                                                    </CustomTooltip>
                                                </TableCell>
                                                <TableCell sx={[TableStyles.tableCell, TableStyles.pointer]} onClick={() => { handleAdminCount(row.userId, 3, row.adminWarns) }}>
                                                    {row.adminWarns}
                                                </TableCell>
                                                <TableCell sx={TableStyles.tableCell}>
                                                    <Box>
                                                        {row.isBlocked ? <Typography sx={commonStyles.warnText}>Blocked</Typography> :
                                                            <Box>
                                                                <CustomTooltip title="Warn the User" >
                                                                    <Icons.ErrorOutlineIcon sx={commonStyles.warnIcon} onClick={() => { handleWarnFun1(row) }} />
                                                                </CustomTooltip>
                                                                <CustomTooltip title="Block the User">
                                                                    <Icons.BlockIcon sx={commonStyles.blockIcon} onClick={() => { handleBlockFun1(row) }} />
                                                                </CustomTooltip>
                                                            </Box>}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination tablePagination={tablePagination2} setTablePagination={setTablePagination2} />
                        </>
                        : <NoData />
                    }
                </>
            }
        </Box>
    )
}

export default Report
