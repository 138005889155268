import React, { useEffect, useState } from 'react';
import { IconButton, MenuItem, Menu, Typography, Box, Badge, Avatar } from '@mui/material';
import { Icons } from '../Styles/Icons';
import { NotificationStyles, commonStyles } from '../Styles/commonStyles';
import { TablePagination } from '../Utils/customField';
import { commonServices } from '../Services/commonServices';
import { ApiConfig } from '../Config/config';
import { onMessage } from 'firebase/messaging';
import { messaging } from '../Notifications/notifications';

const ITEM_HEIGHT = 80;

export default function Notification() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [tablePagination, setTablePagination] = useState({
        totalPages: 10,
        currentPage: 1,
    });

    const [notificationCount, setNotificationCount] = useState(0);
    const getNotificationsCount = async () => {
        const res = await commonServices.getNotificationsCount();
        console.log(res);
        if (res?.status === 200) {
            console.log(res?.data?.data?.count);
            setNotificationCount(res?.data?.data?.count);
        }
    }

    const [notificationData, setNotificationData] = useState([]);
    const handleNotificationClick = async () => {
        let payload = {
            "pageNumber": tablePagination?.currentPage,
            "pageSize": 10,
            "type": 2,
        }
        const res = await commonServices.getNotifications(payload);
        if (res?.status === 200) {
            console.log(res?.data?.data?.count);
            setTablePagination((prev) => ({ ...prev, totalPages: Math.ceil(res?.data?.data?.count / 10) }));
            setNotificationData(res?.data?.data?.rows);
            getNotificationsCount();
        }
    }

    const baseUrl = ApiConfig.baseUrl;

    onMessage(messaging, (payload) => {
        getNotificationsCount();
        console.log("onMessage", payload);
    })

    useEffect(() => {
        getNotificationsCount();
        handleNotificationClick();
    }, [tablePagination?.currentPage]);
    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <Badge color="primary" badgeContent={notificationCount} max={9}>
                    <Icons.NotificationsIcon sx={NotificationStyles.text_white} onClick={handleNotificationClick} />
                </Badge>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 5.5,
                        width: '35vw',
                        backgroundColor: "#1D1B29",
                        color: "#fff",
                        borderRadius: "1rem",
                    },
                }}
            >
                <Typography sx={NotificationStyles.title}>Notification</Typography>
                {notificationData.map((option, index) => (
                    <MenuItem key={index}
                        sx={NotificationStyles.menuItemBox}>
                        <Box sx={commonStyles.flex_center_center}>
                            {option.userProfilePic === "selfie/" ? <Avatar /> :
                                <img src={baseUrl + `${option.userProfilePic}`} alt="profile"
                                    style={NotificationStyles.img} />
                            }
                            <Box>
                                <Typography sx={NotificationStyles.message}>{option.message}</Typography>
                                {/* <Typography sx={NotificationStyles.time}>2 hours ago</Typography> */}
                            </Box>
                        </Box>
                    </MenuItem>
                ))}
                <Box sx={NotificationStyles.TablePagination}>
                    <TablePagination tablePagination={tablePagination} setTablePagination={setTablePagination} />
                </Box>
            </Menu>

        </div>
    );
}