import React, { useEffect, useState } from 'react';
import { commonStyles, loginStyles } from '../../Styles/commonStyles';
import { Box, Grid, InputLabel, Typography, Button, FormControlLabel, Checkbox } from '@mui/material';
import { CustomTextField, CustomPassword } from '../../Utils/customField';
import { useNavigate } from 'react-router-dom';
import { validateEmpty } from '../../Utils/validations';
import { authServices } from '../../Services/authServices';
import { Loader } from '../../Components/lotties';
import { toast } from 'react-toastify';
import { cryptoClient } from '../../Utils/functions';
import { messaging } from '../../Notifications/notifications';
import { getToken } from "firebase/messaging";
import { ApiConfig } from '../../Config/config';
import { Image } from '../../Utils/imageImport';
// import { onMessage } from 'firebase/messaging';


const Login = () => {
  const [fireToken, setFireToken] = useState("");
  const [errorMsg, setErrorMsg] = useState({
    email: "",
    password: "",
  });

  const [loader, setLoader] = useState(false);
  const Navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    const token = fireToken;
    if (!validateEmpty(email, "email", errorMsg, setErrorMsg)) return;
    if (!validateEmpty(password, "password", errorMsg, setErrorMsg)) return;
    const loginRes = await authServices.login({ email, password, token });
    setLoader(true);
    if (loginRes?.status === 200) {
      toast.success(loginRes?.data?.message, commonStyles.toastSuccess);
      if (rememberMe.active) {
        let encryptEmail = await cryptoClient.encryptData(JSON.stringify(email));
        let encryptPassword = await cryptoClient.encryptData(JSON.stringify(password));
        //data1 - Email, data2 - Password
        localStorage.setItem("data1", encryptEmail);
        localStorage.setItem("data2", encryptPassword);
      }
      else {
        localStorage.removeItem("data1");
        localStorage.removeItem("data2");
      }
      sessionStorage.setItem("routeAccess", true);
      sessionStorage.setItem("token", loginRes?.data?.data?.accessToken);
      sessionStorage.setItem("refreshToken", loginRes?.data?.data?.refreshToken);
      setTimeout(() => {
        Navigate("/Admin/Dashboard");
      }, 2000);
    }
    else {
      setLoader(false);
    }
  };

  const [rememberMe, setRememberMe] = useState({
    email: "",
    password: "",
    active: false,
  });
  const RememberMe = async () => {
    const email = localStorage.getItem("data1");
    const password = localStorage.getItem("data2");
    let DecryptEmail = await cryptoClient.decryptData(email);
    let DecryptPassword = await cryptoClient.decryptData(password);
    if (email && password) {
      setRememberMe((prev) => ({ ...prev, email: DecryptEmail, password: DecryptPassword }));
    }
  }

  let count = 0;
  //Request Notification Permission
  const requestPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      console.log(permission);
      if (permission === 'granted') {
        const currentToken = await getToken(messaging, {
          vapidKey: ApiConfig.vapidKey,
        });
        setFireToken(currentToken);
        if (currentToken) {
          setLoader(false);
          toast.success("Notification permission granted", commonStyles.toastSuccess);
          console.log('Current token:', currentToken);
        } else {
          setLoader(false);
          console.log('No registration token available. Request permission to generate one.');
        }
      } else {
        setLoader(false);
        console.log('Unable to get permission to notify.');
      }
    } catch (error) {
      setLoader(false);
      console.error('An error occurred while requesting permission or getting token:', error.message);
      if (count === 0) {
        requestPermission();
        count = 1;
        setLoader(true);
      }
    }
  };

  // const CustomToast = ({ title, message }) => (
  //   <div>
  //     <strong>{title}</strong>
  //     <div>{message}</div>
  //   </div>
  // );

  useEffect(() => {
    setLoader(true);
    sessionStorage.setItem("routeAccess", false);
    sessionStorage.setItem("token", "");
    sessionStorage.setItem("refreshToken", "");
    RememberMe();
    requestPermission();
    // onMessage(messaging, (payload) => {
    //   toast.success(
    //     <CustomToast title={payload?.notification?.title} message={payload?.notification?.body} />, commonStyles.toastMessage);
    // })
  }, []);

  return (
    <>
      <Box sx={loginStyles.mainDiv}>
        {loader && <Loader />}
        <Grid container spacing={0}>
          <Grid item xs={6} sx={loginStyles.mainLogoDiv}>
            <Box sx={loginStyles.leftshadows}></Box>
            <img srcSet={`${Image.tuneConnectLogo_400} 680w, ${Image.tuneConnectLogo} 1920w`} alt="logo" />
          </Grid>
          <Grid item xs={6} sx={loginStyles.mainLogoDiv}>
            <Box sx={loginStyles.rightshadows}></Box>
            <Box component={'form'} onSubmit={handleSubmit}>

              <Typography sx={loginStyles.heading}>Login</Typography>
              <InputLabel sx={loginStyles.labelText}> Email ID </InputLabel>
              <CustomTextField name="email" helperText={errorMsg.email} value={rememberMe.email}
                changeValue={setRememberMe} />

              <InputLabel sx={loginStyles.labelText}> Password </InputLabel>
              <CustomPassword name="password" helperText={errorMsg.password} value={rememberMe.password}
                changeValue={setRememberMe} />

              <Box sx={commonStyles.flex_center_between}>
                <FormControlLabel sx={loginStyles.labelText}
                  control={<Checkbox sx={loginStyles.text_white} checked={rememberMe.active}
                    onChange={() => setRememberMe({ ...rememberMe, active: !rememberMe.active })} />} label="Remember me" />

                <InputLabel sx={loginStyles.warningText}
                  onClick={() => Navigate("/ForgotPassword")}>
                  Forgot Password?</InputLabel>

              </Box>
              <Button type='submit' variant="contained" sx={loginStyles.themeButton}>LOGIN</Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Login
