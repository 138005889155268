import { toast } from "react-toastify";
import { Projectclient } from "../Axios/client";


let errorMsg = false;

export const commonServices = {
    getUserList: async (data) => {
        try {
            const res = await Projectclient.post("getUsersList", data);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    selfieVerification: async (data) => {
        try {
            const res = await Projectclient.post("selfieVerification", data);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    getReportList: async (data) => {
        try {
            const res = await Projectclient.post("getCommentReportList", data);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    updateCommentReport: async (data) => {
        try {
            const res = await Projectclient.post("updateCommentReport", data);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    removeComment: async (data) => {
        try {
            const res = await Projectclient.post("removeComment", data);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },


    getFAQs: async (data) => {
        try {
            const res = await Projectclient.post("getFAQs", data);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    addFAQ: async (data) => {
        try {
            const res = await Projectclient.post("addFAQ", data);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    editFAQ: async (data) => {
        try {
            const res = await Projectclient.post("editFAQ", data);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    deleteFAQ: async (data) => {
        try {
            const res = await Projectclient.delete(`deleteFAQ/${data.id}`, {});
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    privacyPolicy: async () => {
        try {
            const res = await Projectclient.get(`privacyPolicy`);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    termsAndConditions: async () => {
        try {
            const res = await Projectclient.get(`termsAndConditions`);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    cookiePolicy: async () => {
        try {
            const res = await Projectclient.get(`cookiePolicy`);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    updatePrivacyPolicy: async (data) => {
        try {
            const res = await Projectclient.post("termsAndCookiePolicy", data);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    getHelp: async (data) => {
        try {
            const res = await Projectclient.post("supportList", data);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    getPeference: async (data) => {
        try {
            const res = await Projectclient.get("getPreferences/0", data);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    addPeference: async (data) => {
        try {
            const res = await Projectclient.post("addPreference", data);
            return (res);
        } catch (err) {
            console.log("Error", err?.response?.data?.message);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    deletePreference: async (data) => {
        try {
            const res = await Projectclient.delete(`deletePreference/${data}`, {});
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    getDashboard: async () => {
        try {
            const res = await Projectclient.get("getDashboard");
            return (res);
        } catch (err) {
            console.log("the error message is: ",err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    getSongDashboard: async () => {
        try {
            const res = await Projectclient.get("getSongDashboard");
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    getNotificationsCount: async () => {
        try {
            const res = await Projectclient.get("getNotificationsCount/2");
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    getNotifications: async (data) => {
        try {
            const res = await Projectclient.post("getNotifications", data);
            return (res);
        } catch (err) {
            console.log("the error message is: ",err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    reportedblockUserCount: async (data) => {
        try {
            const res = await Projectclient.post("reportedblockUserCount", data);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    reportedblockUserList: async (data) => {
        try {
            const res = await Projectclient.post("reportedblockUserList", data);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    chatReportBlocks : async (data) => {
        try {
            const res = await Projectclient.post("chatReportBlocks", data);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    unBlockUser : async (data) => {
        try {
            const res = await Projectclient.post("unBlockUser", data);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    getCountries : async () => {
        try {
            const res = await Projectclient.get("getCountries");
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    mobileLogin : async (data) => {
        try {
            const res = await Projectclient.post("mobileLogin", data);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    deleteUserByAdmin : async (data) => {
        try {
            const res = await Projectclient.post("deleteUserByAdmin", data);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    subscribeEmailValidation : async (data) => {
        try {
            const res = await Projectclient.post("subscribeEmail", data);
            return (res);
        } catch (err) {
            console.log(err);
            toast.error(err?.response?.data?.message);
            return (err?.response?.data?.message);
            
        }
    },

    subscriptionList: async (data) => {
        try {
            const res = await Projectclient.post("subscriptionList", data);
            return (res);
        } catch (err) {
            console.log(err);
            errorMsg && toast.error(err?.response?.data?.message);
        }
    },

    captchaVerification: async (data) => {
        try {
            const res = await Projectclient.post("captchaVerification", data);
            return (res);
        } catch (err) {
            console.log(err);
            toast.error(err?.response?.data?.message);
        }
    },

}   
