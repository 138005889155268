import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { commonStyles, TableStyles } from '../../Styles/commonStyles';
import { Heading } from '../../Utils/customField';
import BasicTable from '../../Utils/Table';
import { TableBody, TableCell, TableRow, Table, TableContainer, Paper } from '@mui/material';
import { commonServices } from '../../Services/commonServices';
import { TablePagination } from '../../Utils/customField';
import { Loader, NoData } from '../../Components/lotties';


const Subscriber = () => {
    const TableHeadRow = ['S.No', 'Email'];
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [tablePagination, setTablePagination] = useState({
        totalPages: 0,
        currentPage: 1,
        search: '',
        loader: true,
    });

    const subscriptionList = async () => {
        const data = {
            pageNumber: tablePagination.currentPage,
            pageSize: 10,
        }
        const res = await commonServices.subscriptionList(data);
        if (res?.status === 200) {
            setTablePagination((prev) => ({ ...prev, loader: false }));
            setSubscriptionData(res?.data?.data?.rows);
            const totalPages = Math.ceil(res?.data?.data?.count / 10);
            setTablePagination((prev) => ({ ...prev, totalPages: totalPages }));
        }
    }

    useEffect(() => {
        subscriptionList()
    }, [tablePagination.currentPage])

    return (
        <>
            {tablePagination.loader && <Loader />}
            <Box sx={commonStyles.outlet_root}>
                <Heading title="Subscribers" />

                <TableContainer component={Paper} sx={TableStyles.tableContainer}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <BasicTable TableHeadRow={TableHeadRow} />
                        <TableBody sx={TableStyles.tableBody}>
                            {subscriptionData.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell sx={TableStyles.tableCell}>{index + 1}</TableCell>
                                    <TableCell sx={TableStyles.tableCell}>{row?.email}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination tablePagination={tablePagination} setTablePagination={setTablePagination} />

            </Box>
        </>
    )
}

export default Subscriber
