import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import RootLayout from '../Layout/rootLayout';
import Dashboard from '../Pages/Admin/dashboard';
import UserManagement from '../Pages/Admin/userManagement';
import Report from '../Pages/Admin/report';
import ContentManagement from '../Pages/Admin/contentManagement';
import ConnectionPreferences from '../Pages/Admin/connectionPreferences';
import HelpSupport from '../Pages/Admin/helpSupport';
import ReportList from '../Pages/Admin/reportList';
import Subscriber from '../Pages/Admin/subscriber';

const PrivateCheck = ({ children }) => {
  const isAuth = sessionStorage.getItem("routeAccess");
  return isAuth==="true" ? children : <Navigate to="/" />;
};

const PrivateRoutes = () => {
  return (
    <>
      <Routes>
        <Route path='Admin' element={<RootLayout />}>
          <Route path="Dashboard"
            element={
              <PrivateCheck>
                <Dashboard />
              </PrivateCheck>
            }
          />
          <Route path="UserManagement"
            element={
              <PrivateCheck>
                <UserManagement />
              </PrivateCheck>}
          />
          <Route path="Report" element={
            <PrivateCheck>
              <Report />
            </PrivateCheck>
          } />
          <Route path="ContentManagement" element={
            <PrivateCheck>
              <ContentManagement />
            </PrivateCheck>
          } />
          <Route path="ConnectionPreferences" element={
            <PrivateCheck>
              <ConnectionPreferences />
            </PrivateCheck>
          } />
          <Route path="Help&Support" element={
            <PrivateCheck>
              <HelpSupport />
            </PrivateCheck>
          } />

          <Route path="ReportListDetails" element={
            <PrivateCheck>
              <ReportList />
            </PrivateCheck>
          } />

          <Route path="Subscriber" element={
            <PrivateCheck>
              <Subscriber />
            </PrivateCheck>
          } />
          
        </Route>
      </Routes>
    </>
  )
}

export default PrivateRoutes
