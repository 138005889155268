import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { TableStyles } from '../Styles/commonStyles';
import { TableRow } from '@mui/material';

export default function BasicTable({ TableHeadRow }) {

  const tableLength = TableHeadRow.length - 1;
  const tableClasses = (index) =>
    index === 0 ? TableStyles.tableHeadFirstCell :
      index === tableLength ? TableStyles.tableHeadLastCell : TableStyles.tableHeadCell

  return (
    <TableHead sx={TableStyles.tableHead}>
      <TableRow>
      {TableHeadRow.map((head, index) => (
        <TableCell key={index}
          sx={tableClasses(index)}>
          {head}
        </TableCell>
      ))}
      </TableRow>
    </TableHead>
  );
}
