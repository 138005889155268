import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const Editor = ({ data, setData, tabStatus }) => {
    // console.log(tabStatus);
    let modules = {};
    if (tabStatus === 1) {
         modules = {
            toolbar: [
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ],
        };
    }
    else {
         modules = {
            toolbar: [
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['link'],
            ],
        };
    }

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];
    return (
        <ReactQuill
            theme="snow"
            value={data}
            onChange={setData}
            modules={modules}
            formats={formats}
            placeholder="Enter your text here..."
        />
    )
}