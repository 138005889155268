import React, { useEffect, useState } from 'react';
import { Box, Grid, styled, Paper, Typography, Avatar } from '@mui/material';
import { commonStyles, dashboard } from '../../Styles/commonStyles';
import { DashboardImages } from '../../Utils/imageImport';
import { DonutChart, BarGraph } from '../../Utils/chart';
import { commonServices } from '../../Services/commonServices';  
import CountUp from 'react-countup';
import { NavLink } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#434343',
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: 'center',
  color: '#fff',
}));

const GridItem = ({ src, count, title, link }) => {
  return (
    <Grid item xs={2}>
      <NavLink to={link}>
        <Item sx={dashboard.flexColumn}>
          <Avatar src={src} sx={dashboard.minAvatar} variant="rounded" />
          <Typography sx={dashboard.countHeading}> <CountUp start={0} end={count} duration={3} /></Typography>
          <Typography sx={dashboard.countTitle}>{title}</Typography>
        </Item>
      </NavLink>
    </Grid>
  )
}

const Dashboard = () => {

  const [dashboardData, setDashboardData] = useState([{
    iOsUsers: 0,
    androidUsers: 0,
    inCompleteProfiles: 0,
    verifiedUsers: 0,
    reportedUsers: 0,
    ticketsRaised: 0,
    genderData: [{
      male: 0,
      female: 0,
      nonBinary: 0
    }]
  }]);

  const getDashboard = async () => {
    const res = await commonServices.getDashboard();
    if (res?.status === 200) {
      setDashboardData(res?.data?.data);
    }
  }

  const [graphData, setGraphData] = useState([]);
  const getSongDashboard = async () => {
    const res = await commonServices.getSongDashboard();
    if (res?.status === 200) {
      setGraphData(res?.data?.data)
    }
  }

  useEffect(() => {
    getDashboard();
    getSongDashboard();
  }, [])

  return (
    <>
      <Box sx={commonStyles.outlet_root}>
        <Grid container spacing={2}>
          <GridItem src={DashboardImages.contact} count={dashboardData?.iOsUsers} title="iOS Users" link="/Admin/UserManagement" />
          <GridItem src={DashboardImages.contact} count={dashboardData?.androidUsers} title="Android Users" link="/Admin/UserManagement" />
          <GridItem src={DashboardImages.pendingApprovals} count={dashboardData?.inCompleteProfiles} title="Pending Approvals" link="/Admin/UserManagement" />
          <GridItem src={DashboardImages.approvedUser} count={dashboardData?.verifiedUsers} title="Verified Users" link="/Admin/UserManagement" />
          <GridItem src={DashboardImages.reportUser} count={dashboardData?.reportedUsers} title="Reported Users" link="/Admin/Report" />
          <GridItem src={DashboardImages.tickets} count={dashboardData?.ticketsRaised} title="Tickets raised" link="/Admin/Help&Support" />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box sx={dashboard.DonutChartDiv}>
              <Box sx={dashboard.DonutChartText}>

                <Typography sx={dashboard.countHeading2}>Analytics</Typography>

                <NavLink to={"/Admin/UserManagement"}>
                  <Typography sx={dashboard.viewAll}>View All</Typography>
                </NavLink>
              </Box>
              <DonutChart Reportdata={dashboardData?.genderData} />
            </Box>
          </Grid>
          <Grid item xs={8} >
            <Box sx={dashboard.DonutChartDiv}>
              {/* <CalendarModel DateFunction={DateFunction} /> */}
              <Typography sx={commonStyles.dashboardCalendar}>Songs Matrix</Typography>
              <BarGraph graphData={graphData} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Dashboard
