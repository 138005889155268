import React, { useState } from 'react'
import { loginStyles, commonStyles } from '../../Styles/commonStyles';
import { Box, Grid, InputLabel, Typography, Button } from '@mui/material';
import { Image } from '../../Utils/imageImport';
import { CustomTextField2 } from '../../Utils/customField';
import { validateEmpty } from '../../Utils/validations';
import { authServices } from '../../Services/authServices';
import { toast } from 'react-toastify';
import { Loader } from '../../Components/lotties';

const ForgotPassword = () => {
    const [loader, setLoader] = useState(false);
    const [errorMsg, setErrorMsg] = useState({
        email: "",
    })

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        if (!validateEmpty(email, "email", errorMsg, setErrorMsg)) return;
        setLoader(true);
        const res = await authServices.forgotPassword({ email: email });
        if (res?.status === 200) {
            setLoader(false);
            console.log(res);
            toast.success(res?.data?.message, commonStyles.toastSuccess);
        }
    };

    return (
        <>
            <Box sx={loginStyles.mainDiv}>
                {loader && <Loader />}
                <Grid container spacing={0}>
                    <Grid item xs={6} sx={loginStyles.mainLogoDiv}>
                        <Box sx={loginStyles.leftshadows}></Box>
                        <img srcSet={`${Image.tuneConnectLogo_400} 680w, ${Image.tuneConnectLogo} 1920w`} alt="logo" />
                    </Grid>
                    <Grid item xs={6} sx={loginStyles.mainLogoDiv}>
                        <Box sx={loginStyles.rightshadows}></Box>
                        <Box component={'form'} onSubmit={handleSubmit}>
                            <Typography sx={loginStyles.heading}>Forgot Password</Typography>
                            <InputLabel sx={loginStyles.labelText}> Email registered Email ID </InputLabel>
                            <CustomTextField2 name="email" helperText={errorMsg.email} />
                            <Button type='submit' variant="contained" sx={loginStyles.themeButton}>Submit</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default ForgotPassword
