import * as React from 'react';
import { Button, Dialog, DialogContent, Typography, TextField, Box, Slide } from '@mui/material';
import { ApiConfig } from '../Config/config';
import { commonStyles } from '../Styles/commonStyles';
import { Icons } from '../Styles/Icons';

export function Warnmodel({ open, setOpen, warnUserData, handleInputField, handleWarnApi }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={commonStyles.model}
      >
        <DialogContent sx={commonStyles.modelrootDiv}>
          <Typography variant="h5" sx={commonStyles.heading}>  Description </Typography>
          <Button sx={commonStyles.closeIcon}>
            <Icons.CancelIcon onClick={handleClose} />
          </Button>
          <Box sx={commonStyles.mt1}>
            <TextField fullWidth placeholder="Enter here" id="fullWidth"
              value={warnUserData?.description}
              onChange={handleInputField} />
          </Box>
          <Button onClick={handleWarnApi} sx={commonStyles.submitButton}>Submit</Button>
        </DialogContent>
      </Dialog>
    </>
  );
}


// Status - 1 title = "Are You Sure Want to Remove Comment"
// Status - 2 title = "Are You Sure Want to Remove FAQ"
// Status - 3 title = "Are You Sure Want to Remove Social Peference"
// Status - 4 title = "Are You Sure Want to Logout"
// Status - 5 title = "Are You Sure Want to UnBlock User"
// Status - 6 title = "Are You Sure Want to Block User"
// Status - 31 title = "Are You Sure Want to Remove this Gender"
// Status - 32 title = "Are You Sure Want to Remove this Music Interest"
// Status - 33 title = "Are You Sure Want to Remove this Language"

export function CommonModel({ open, setOpen, status, handleRemoveApi }) {
  
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={commonStyles.model}
      >
        <DialogContent sx={commonStyles.modelrootDiv}>
          <Typography variant="h6" sx={commonStyles.heading2}>
            {status === 1 && "Are You Sure Want to Remove Comment"}
            {status === 2 && "Are You Sure Want to Remove FAQ"}
            {status === 3 && "Are You Sure Want to Remove Social Peference"}
            {status === 4 && "Are You Sure Want to Logout"}
            {status === 5 && "Are You Sure Want to UnBlock User"}
            {status === 6 && "Are You Sure Want to Block User"}
            {status === 7 && "Are You Sure Want to Delete User"}
            {status === 31 && "Are You Sure Want to Remove this Gender"}
            {status === 32 && "Are You Sure Want to Remove this Music Interest"}
            {status === 33 && "Are You Sure Want to Remove this Language"}
          </Typography>
          <Button sx={commonStyles.closeIcon}>
            <Icons.CancelIcon onClick={handleClose} />
          </Button>
          <Button sx={commonStyles.rejectButton} onClick={handleClose}>No</Button>
          <Button sx={commonStyles.submitButton} onClick={handleRemoveApi}>Yes</Button>
        </DialogContent>
      </Dialog>
    </>
  );
}


export function AddSocialModel({ open, setOpen, value, setValue, handlefunction }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={commonStyles.model}
      >
        <DialogContent sx={commonStyles.modelrootDiv}>
          <Typography variant="h5" sx={commonStyles.heading}> Add Title </Typography>
          <Button sx={commonStyles.closeIcon}>
            <Icons.CancelIcon onClick={handleClose} />
          </Button>
          <Box sx={commonStyles.mt1}>
            <TextField fullWidth placeholder="Enter here" id="fullWidth"
              value={value?.name} 
              onChange={(e) => {
                const inputValue = e.target.value;
                const formattedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1).toLowerCase();
                setValue((prev) => ({ ...prev, name: formattedValue }));
              }}
            />
          </Box>
          <Button sx={commonStyles.submitButton} onClick={handlefunction}>Submit</Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction={'left'} ref={ref} {...props} />;
});


// Profile Image
export function ProfileImage({ open, setOpen, img, setImg }) {
  const baseUrl = ApiConfig.baseUrl;
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setImg('');
    }, [500]);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={commonStyles.model}
      >
        <Button sx={commonStyles.closebtn}>
          <Icons.CancelIcon onClick={handleClose} />
        </Button>
        <img src={baseUrl + img} alt="profile"
          style={commonStyles.modelImg}
        />
      </Dialog>
    </>
  );
}

export function Warnmodel2({ open, setOpen, warnUserData, handleInputField, handleWarnApi }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={commonStyles.model}
      >
        <DialogContent sx={commonStyles.modelrootDiv}>
          <Typography variant="h5" sx={commonStyles.heading}> Description </Typography>
          <Button sx={commonStyles.closeIcon}>
            <Icons.CancelIcon onClick={handleClose} />
          </Button>
          <Box sx={commonStyles.mt1}>
            <TextField fullWidth placeholder="Enter here" id="fullWidth"
              value={warnUserData?.description}
              onChange={handleInputField} />
          </Box>
          <Button onClick={handleWarnApi} sx={commonStyles.submitButton}>Submit</Button>
        </DialogContent>
      </Dialog>
    </>
  );
}