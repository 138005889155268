import React, { useEffect, useState } from 'react'
import { commonStyles } from '../../Styles/commonStyles';
import { Box } from '@mui/material';
import { Heading, SocialButton, Heading2, AddButtonPlus } from '../../Utils/customField';
import { commonServices } from '../../Services/commonServices';
import { AddSocialModel, CommonModel } from '../../Utils/model';
import { toast } from 'react-toastify';
import { Loader } from '../../Components/lotties';

const ConnectionPreferences = () => {
    const [preference, setPerference] = useState([]);
    const [loader, setLoader] = useState(true);
    const [DelModel, setDelModel] = useState(false);
    //Model
    const [open, setOpen] = useState(false);
    const handleOpen = (type) => {
        setOpen(true);
        setAddNew((prev) => ({ ...prev, type: type, name: '' }))
    }

    //Add New Social Peference
    const [AddNew, setAddNew] = useState({
        name: '',
        type: '',
        action: 0, // 0 - Add
    })

    //Get Peference List API
    const getPeference = async () => {
        const res = await commonServices.getPeference()
        if (res.status === 200) {
            setPerference(res?.data?.data?.rows);
            setTimeout(() => {
                setLoader(false)
            }, 500)
        }
    }

    const [deleteId, setDeleteId] = useState(0);
    const [modelStatus, setModelStatus] = useState(0);
    //Delete Peference Function
    const deleteFun = (id, type) => {
        if (type === 1) {
            setModelStatus(31);
        }
        if (type === 2) {
            setModelStatus(32);
        }
        if (type === 3) {
            setModelStatus(33);
        }
        console.log(type);
        setDeleteId(id);
        setDelModel(true);
    }

    const handleRemoveApi = async () => {
        console.log(deleteId);
        const res = await commonServices.deletePreference(deleteId)
        if (res?.status === 200) {
            setDelModel(false);
            toast.success(res?.data?.message, commonStyles.toastSuccess);
            getPeference();
        }
    }

    // UseEffect
    useEffect(() => {
        getPeference();
    }, [])

    //Add New Social Peference
    const handleAddNew = async () => {
        console.log(AddNew);
        const res = await commonServices.addPeference(AddNew)
        if (res?.status === 200) {
            setOpen(false);
            toast.success(res?.data?.message, commonStyles.toastSuccess);
            getPeference();
        }
    }

    return (
        <>
            <Box sx={commonStyles.outlet_root}>
                {loader && <Loader />}
                <Heading title="Connection Preferences" />
                <AddSocialModel open={open} setOpen={setOpen} value={AddNew} setValue={setAddNew} handlefunction={handleAddNew} />
                <CommonModel open={DelModel} setOpen={setDelModel} status={modelStatus} handleRemoveApi={handleRemoveApi} />
                {/* 1 - Gender */}
                <Box sx={commonStyles.mt1}>
                    <Heading2 title="Gender" />
                    {preference.filter((value) => value.type === 1).map((value, index) => (
                        <SocialButton key={index} titleText={value?.name} id={value?.id} SendId={deleteFun} type={value?.type} />
                    ))}
                    <AddButtonPlus handleOpen={() => { handleOpen(1) }} />
                </Box>

                {/* 2 - Music Interest */}
                <Box sx={commonStyles.mt1}>
                    <Heading2 title="Music Interest" />
                    {preference.filter((value) => value.type === 2).map((value, index) => (
                        <SocialButton key={index} titleText={value?.name} id={value?.id} SendId={deleteFun} type={value?.type} />
                    ))}
                    <AddButtonPlus handleOpen={() => { handleOpen(2) }} />
                </Box>

                {/* 3 - Language */}
                <Box sx={commonStyles.mt1}>
                    <Heading2 title="Language" />
                    {preference.filter((value) => value.type === 3).map((value, index) => (
                        <SocialButton key={index} titleText={value?.name} id={value?.id} SendId={deleteFun} type={value?.type} />
                    ))}
                    <AddButtonPlus handleOpen={() => { handleOpen(3) }} />
                </Box>
            </Box>
        </>
    )
}

export default ConnectionPreferences
