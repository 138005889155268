import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { ApiConfig } from "../Config/config";

const firebaseConfig = {
    apiKey: ApiConfig.apiKey,
    authDomain: ApiConfig.authDomain,
    projectId: ApiConfig.projectId,
    storageBucket: ApiConfig.storageBucket,
    messagingSenderId: ApiConfig.messagingSenderId,
    appId: ApiConfig.appId,
    measurementId: ApiConfig.measurementId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export let messaging;

if ('serviceWorker' in navigator && 'PushManager' in window) {
    try {
        messaging = getMessaging(app);
        console.log('Firebase Messaging is supported and initialized.');
    } catch (error) {
        console.error('Error initializing Firebase Messaging:', error);
    }
} else {
    console.warn('This browser does not support the APIs required for Firebase Messaging.');
}

